import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box } from '@mui/material';
import { styled } from 'styled-components';
import { Dot } from 'component/common/Dot/dot'
import { movePackagesToBatch, fetchBatchPackageDetails, createBatch } from 'features/batch/batchAction'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { resetSuccessState } from 'features/batch/batchSlice'
import { CustomModal } from 'component/common/Modal/CustomModal'
import { DateTimePicker } from 'component/common/Input/DateTimePicker'
import { getUniqueStopCount } from 'utils/batchHelper'

const ModalContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border: 1px solid $primary-color;
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    z-index : 10;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 20px;
}`

export const BatchMoveModal = ({ isModelOpen, handleClose, batches, selectedPackages, fetchBatchesData, resetSelectedPackages, userTimeZone }) => {
    const dispatch = useDispatch();
    const { loading, updateBatchSuccess } = useSelector(
        (state) => state?.batches
    )
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [batchStartTime, setBatchStartTime] = useState(null);
    const [modalProps, setModalProps] = useState(null)
    const [batchAction, setBatchAction] = useState('create');
    const stopCount = getUniqueStopCount(selectedPackages)

    useEffect(() => {
        if (updateBatchSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Bacth updated successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                handleClose()
                setModalProps(null)
                resetSelectedPackages()
                dispatch(resetSuccessState())
                dispatch(fetchBatchPackageDetails({
                    batchIds: batches?.map((batch => batch?.batchId))?.join(',')
                }))
                fetchBatchesData()
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [updateBatchSuccess]);

    const handleOptionChange = (event) => {
        setSelectedBatch(event.target.value);
    };

    const handleBatchActionChange = (event) => {
        setBatchAction(event.target.value);
        setSelectedBatch(''); // Reset selected batch when switching options
    };

    const handleFormSubmit = () => {
        const packages = selectedPackages?.map((parcel => parcel?.trackingNumber))
        let params = {}
        if (batchAction === 'move') {
            params = {
                batchId: selectedBatch,
                trackingNumbers: packages,
                isBatchMapView:true
            }
            dispatch(movePackagesToBatch(params))
        }
        else {
            params = {
                routeStartTime: batchStartTime,
                trackingNumbers: packages,
                methodType: 'put',
                isBatchMapView: true
            }
            dispatch(createBatch(params))
        }
    }

    const handleDateChange = (date) => {
        setBatchStartTime(date);
    };

    const isButtonEnabled = () => {
        if (batchAction === 'create' && batchStartTime !== null) {
            return true
        }
        if (batchAction === 'move' && selectedBatch) {
            return true
        }
        return false
    }

    return (
        <>
            <CustomModal
                open={isModelOpen}
                onClose={handleClose}
            >
                <ModalContainer>
                    <FormLabel component="legend">Parcel Count : {selectedPackages?.length} , Stop Count : {stopCount}</FormLabel>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="batch-action"
                            name="batchAction"
                            value={batchAction}
                            onChange={handleBatchActionChange}
                        >
                            <FormControlLabel
                                value="create"
                                control={<Radio />}
                                label="Create New Batch"
                            />
                            <FormControlLabel
                                value="move"
                                control={<Radio />}
                                label={`Move Packages to Existing Batch`}
                            />
                        </RadioGroup>


                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            {batchAction === 'move' ?
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="options"
                                        name="options"
                                        value={selectedBatch}
                                        onChange={handleOptionChange}
                                    >
                                        {batches?.map((batch) => (
                                            <FormControlLabel
                                                key={batch?.batchId}
                                                value={batch?.batchId}
                                                control={<Radio />}
                                                label={
                                                    <React.Fragment>
                                                        <Dot color={batch?.color} />
                                                        {batch?.batchName}
                                                    </React.Fragment>
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl> :
                                <FormControl component="fieldset">
                                    <DateTimePicker name='batchStartTime' label='Batch Start Time' width={'18rem'} value={batchStartTime} handleFieldChange={handleDateChange} required={true} timeZone={userTimeZone} />
                                </FormControl>
                            }
                        </Box>
                    </FormControl>
                    <ButtonContainer>
                        <Button onClick={handleFormSubmit} variant='outlined' disabled={!isButtonEnabled()}>Save</Button>
                        <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                    </ButtonContainer>
                </ModalContainer>
            </CustomModal>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </>
    )

}