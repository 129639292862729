import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import 'component/dailyRoaster/AddDuty.scss'
import { SelectField } from 'component/common/Input/SelectField'
import { Button } from '@mui/material';
import { validateForm } from 'utils/formValidator'
import { getDriverList } from 'utils/common'
import { updateRoaster } from 'features/dailyRoaster/dailyRoasterAction'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { resetSuccessData } from 'features/dailyRoaster/dailyRoasterSlice'
import { Loader } from 'component/common/Loader/Loader'
import { fetchDriversForRoaster } from 'features/driverManagement/driverManagementAction'
import useFieldChange from 'hooks/useFieldChange';

const formInitialState = {
    driver: '',
}

export const AssignDriver = ({ isModelOpen, handleClose, route }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [modalProps, setModalProps] = useState(null)

    const { driversForRoasters, loading } = useSelector(
        (state) => state?.driverManagement
    )

    const { updateRoasterSuccess, error } = useSelector(
        (state) => state?.dailyRoaster
    )

    useEffect(() => {
        dispatch(fetchDriversForRoaster(route?.dailyRouteScanSummaryVM?.routeId))
    }, [])

    useEffect(() => {
        if (updateRoasterSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: formValues?.driver ? 'Driver Assigned Successfully!' : 'Driver Removed Successfully!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                handleCancel()
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [updateRoasterSuccess]);
    
    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: 'error'
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (route?.driverId) {
            setFormValues({
                driver: { label: route?.driverName, value: route?.driverId },
            });
        }
    }, [route])

    const handleFormSubmit = () => {
        const notRequiredFields = []
        if(route?.driverId){
            notRequiredFields.push('driver')
        }
        const errors = validateForm(formValues, notRequiredFields);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            const data = {}
            setFormErrors({})
            const { driver } = formValues
            data['driverId'] = driver?.value || route?.driverId
            data['routeId'] = route?.dailyRouteScanSummaryVM?.routeId
            data['operation'] = driver?.value? 'assign': 'remove'
            dispatch(updateRoaster(data))
        }
    }

    const handleCancel = () => {
        setFormValues(formInitialState)
        dispatch(resetSuccessData())
        handleClose()
    }

    return (
        <>
            <Modal
                open={isModelOpen}
                onClose={handleCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='add-duty-modal'>
                    <span className='title'>{'Assign Driver'}</span>
                    <div>
                        <SelectField label='Driver' name='driver' options={getDriverList(driversForRoasters)} width={'18rem'}
                            value={formValues?.driver} handleFieldChange={handleFieldChange} error={formError?.driver} isLoading={loading} isClearable={true} />
                    </div>
                    <div className='button-container'>
                        <Button onClick={handleFormSubmit} variant='outlined'>Save</Button>
                        <Button onClick={handleCancel} variant='outlined'>Cancel</Button>
                    </div>

                </div>
            </Modal>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </>

    )

}