import React from "react";
// import { SearchBar } from "component/common/SearchBar/SearchBar"
import { IconButton } from "component/common/Button/Button";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { FilterList as FilterIcon } from "@mui/icons-material";
import Export from "./export";
import { iconStyle } from "utils/common";
import "./tableHeader.scss";

export const TableHeader = ({
  action,
  handleTableFilterClick,
  columns,
  data,
  exportFileName,
  onRefreshClick = null,
}) => {
  const handleRefreshClick = () => {
    onRefreshClick ? onRefreshClick() : window.location.reload();
  };

  return (
    <div className="tableHeader">
      {/* <SearchBar/> */}
      <div className='header'>{action}</div>
      <div>
        <IconButton
          variant="outlined"
          dataTestId="refresh-button"
          startIcon={<RefreshIcon style={iconStyle} />}
          onClick={handleRefreshClick}
        >
          Refresh
        </IconButton>
        {handleTableFilterClick ? (
          <IconButton
            variant="outlined"
            dataTestId="filter-button"
            startIcon={<FilterIcon style={iconStyle} />}
            onClick={handleTableFilterClick}
          >
            Filter
          </IconButton>
        ) : null}
        <Export columns={columns} data={data} exportFileName={exportFileName} />
      </div>
    </div>
  );
};
