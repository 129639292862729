import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { CardDetail } from 'component/dashboard/cardDetail'
import { Layout } from 'component/common/Layout/Layout';
import { SelectInput } from "component/common/Input/SelectInput"
import { SelectField } from 'component/common/Input/SelectField'
import { fetchDataCenters } from 'features/userManagement/userManagementAction'
import { fetchLiveTrackingRoutes, fetchLiveTrackingSummary, getRoute, getCurrentLocation } from 'features/route/routeAction'
import { getDataCentersList } from 'utils/userAccountHelper'
import { getRoutSummary, getInProgressPackages } from 'utils/liveTrackingHelper'
import { Loader } from 'component/common/Loader/Loader'
import 'component/userManagement/userAccount/UserAccount.scss'
import { getDCObject, getGlobalFilter, convertDateRangeToUTC } from 'utils/common'
import './liveTracking.scss';
import { LiveTrackingMap as LiveTrackingHereMap } from './liveTrackingMap1'
import { PrimaryButton } from 'component/common/Button/Button'
import { useNavigate, useLocation } from "react-router-dom";
import { LiveTrackingMap } from './liveTrackingMap'
import { DeliveryStatusFilter } from 'component/packages/DeliveryStatusFilter'
import { setPackageFilter } from 'features/packages/packagesSlice'
import { getCurrentDateForFilter, getUserDCOption } from 'utils/common'
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';
import { LiveRoute } from './LiveRoute'
import { LiveTrackingSummary } from './LiveTrackingSummary';
import { DateRangePickerField } from 'component/common/Input/DateRangePicker/DateRangePickerField'
import { resetError } from 'features/route/routeSlice'

const INTERVAL_TIME = 300000  // 5 minute timer

const formInitialState = {
    dc: '',
    searchText: null,
    searchKey: 'driverName',
    startDate: getCurrentDateForFilter(),
    endDate: getCurrentDateForFilter()
}

const selectOptions = [{
    label: 'Name',
    value: 'driverName'
},
{
    label: 'Route ID',
    value: 'routeId'
}]

export const LiveTracking = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const showHereMap = queryParams.get('show_here_map');
    const route = queryParams.get('routeId');

    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [clearSearchClicked, setClearSearchClicked] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState(route || null)
    const [showStops, setShowStops] = useState(false)
    const formValuesRef = useRef(formValues);

    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const dispatch = useDispatch();
    const { dataCenters } = useSelector(
        (state) => state?.userManagement
    )
    const { highPriority } = useSelector(
        (state) => state?.deliveryStatusManagement
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { routeSummary, liveTrackingRoutes, isLoading, data: routeData, loading, routeFollowed } = useSelector(
        (state) => state.route
    )

    const routeDetail = selectedRoute ? routeData[selectedRoute] : null

    //To Do - Remove this after backedn integration
    const summary = getRoutSummary(routeSummary?.routes)

    const liveTrackingData = liveTrackingRoutes?.length ? getInProgressPackages(liveTrackingRoutes) : []
    const dcCorodinates = getDCObject(formValues?.dc?.value, dataCenters)

    useEffect(() => {
        dispatch(fetchDataCenters())
        const userDc = getUserDCOption(user)
        if (userDc) {
            setFormValues((prevValues) => ({
                ...prevValues,
                dc: userDc,
            }));
        }
        if (route) {
            setFormValues((prevValues) => ({
                ...prevValues,
                searchText: route,
                searchKey: 'routeId',
                startDate: '',
                endDate: ''
            }))
        }
        return () => {
            // This code will run when the component unmounts
            dispatch(resetError())
        };
    }, [])

    const handleDateRangeSelect = (startDate, endDate) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            startDate: startDate,
            endDate: endDate,
        }));
        formValuesRef.current.startDate = startDate;
        formValuesRef.current.endDate = endDate;
    }

    const handleMapRouteClick = (routeId, showStops) => {
        setSelectedRoute(routeId)
        setShowStops(showStops)
        if (routeId !== selectedRoute || showStops) {
            dispatch(getRoute(routeId))
            dispatch(getCurrentLocation(routeId))
        }
    }

    const fetchData = () => {
        const globalFilter = getGlobalFilter(highPriority)
        let filterValue = {}

        const { startDate, endDate } = formValuesRef.current
        const dateFilter = convertDateRangeToUTC(startDate, endDate, "YYYY-MM-DD")
        filterValue['dcName'] = getUserDCOption(user)?.value

        // if (formValues?.dc) {
        //     filterValue['dcName'] = formValues?.dc?.value
        // }
        if (formValues?.searchText) {
            filterValue[formValues?.searchKey] = formValues?.searchText
        }
        dispatch(fetchLiveTrackingRoutes({
            ...filterValue,
            ...globalFilter,
            ...dateFilter,
            widget: 'Live-Tracking'
        }))
    }


    const fetchLiveTrackingSumaryData = () => {
        const { startDate, endDate } = formValuesRef.current
        const dateFilter = convertDateRangeToUTC(startDate, endDate, "YYYY-MM-DD")

        if (!route) {
            dispatch(fetchLiveTrackingSummary({
                ...dateFilter,
                dcName : getUserDCOption(user)?.value
            }))
        }
    }

    const fetchLongPollingData = () => {
        fetchData()
        fetchLiveTrackingSumaryData()
    }

    useEffect(() => {
        // Start the initial long poll. This will be called every 30 minutes
        const pollId = setInterval(fetchLongPollingData, INTERVAL_TIME);

        // Cleanup function to stop the long poll when the component is unmounted
        return () => {
            clearInterval(pollId);
        };
    }, []);


    useEffect(() => {
        fetchData()
        fetchLiveTrackingSumaryData()
        setSelectedRoute(null)
    }, [highPriority, formValues?.startDate, formValues?.endDate])

    useEffect(() => {
        if (clearSearchClicked) {
            fetchData();
            setClearSearchClicked(false)
        }
    }, [clearSearchClicked]);


    const onClearClick = () => {
        setClearSearchClicked(true)
        // Update the state without the second callback argument
        setFormValues((prevValues) => ({
            ...prevValues,
            'searchText': null,
        }));
    };

    const handleShowHereMap = () => {
        setShowStops(false)
        setSelectedRoute(null)
        navigate(showHereMap ? urls?.LIVE_TRACKING_WIDGET_URL : `${urls?.LIVE_TRACKING_WIDGET_URL}?show_here_map=true`)
    }

    const handleNavigation = (params) => {
        dispatch(setPackageFilter(params))
        const url = `${urls?.PARCEL_WIDGET_URL}?routeId=${encodeURIComponent(params.routeId)}`;
        window.open(url, '_blank');
    }

    return (
        <Layout headerTitle={'Live Tracking'} backUrl='/' globalFilter={<DeliveryStatusFilter showSLA={false} />} action={
            <>
                <div><PrimaryButton className='buton-primary' type="button" label={showHereMap ? 'MapBox' : 'HereMap'} height={'30px'} onClick={handleShowHereMap} /></div>
                <SelectField name='dc' width={isMobile ? '100%' : '10rem'} options={getDataCentersList(dataCenters)} isDisabled={true} value={formValues?.dc} handleFieldChange={handleFieldChange} isClearable={true} />
                {!route ?
                    <DateRangePickerField filterStartDate={formValues?.startDate} filterEndDate={formValues?.endDate} name='startDate' type='date' width={isMobile ? '100%' : '12rem'} handleFieldChange={handleDateRangeSelect} max={new Date()?.toISOString()?.split('T')?.[0]}
                    /> : null}
            </>
        }
        >
            <CardDetail>
                <div className='live-tracking-container'>
                    {!route ? <div className='live-tracking-content'>
                        <LiveTrackingSummary data={summary} isMobile={isMobile} />
                    </div> : null}
                    <div className='live-tracking-content'>
                        <div className='tracking-container'>
                            <SelectInput searchKeyName='searchKey' selectOptions={selectOptions} onSelectChange={handleFieldChange} value={formValues?.searchText} selectValue={formValues?.searchKey} onInputChange={handleFieldChange} searchTextName='searchText' onSearchClick={fetchData} placeHolder={formValues?.searchKey === 'driverName' ? 'Search Driver Name' : 'Search Route ID'} onClearClick={onClearClick} inputValue={formValues?.searchText} />
                            <div className='tracking-detail' dataTestId ='tracking-detail'>
                            <LiveRoute data={liveTrackingData} user={user} handleMapRouteClick={handleMapRouteClick} routeStops={routeDetail ? routeDetail?.routeStops : []} selectedRoute={selectedRoute} showStops={showStops} handleNavigation={handleNavigation}/>
                            </div>
                        </div>
                        <div className='map-container'>
                            {showHereMap ?
                                <LiveTrackingHereMap data={liveTrackingData} routeStops={routeDetail && selectedRoute ? routeDetail?.routeStops : []} showStops={showStops} loading={loading} routeFollowed={showStops ? routeFollowed : []} currentLat={routeDetail?.currentLat} currentLon={routeDetail?.currentLOn} center={dcCorodinates} />
                                :
                                <LiveTrackingMap data={liveTrackingData} routeStops={routeDetail && selectedRoute ? routeDetail?.routeStops : []} showStops={showStops} loading={loading} routeFollowed={showStops ? routeFollowed : []} currentLat={routeDetail?.currentLat} currentLon={routeDetail?.currentLOn} center={dcCorodinates} />}
                        </div>
                    </div>
                </div>
            </CardDetail>
            {isLoading ? <Loader isLoading={isLoading} /> : null}
        </Layout>
    )
}