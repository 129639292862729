import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Link } from '@mui/material';
import { Layout } from 'component/common/Layout/Layout';
import { fetchPackages, markHighPriorityAndSLA, updateNotificationStatus, markOnHold } from 'features/packages/packagesAction'
import { CardDetail } from 'component/dashboard/cardDetail'
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { TableHeader } from 'component/dataTable/tableHeader';
import { getConstantValue, getDate } from 'utils/common'
import { iconStyle } from 'utils/common'
import { packageStatus, packageStatusEnum, remediationPackagestatus } from 'constants/packageStatus'
import 'component/userManagement/userAccount/UserAccount.scss'
import { EditPackageAddress } from './EditPackageAddress'
import { resetSuccessState, resteStreetPerfectDetails, resetError, togglePackageSelection, clearSelectedPackages } from 'features/packages/packagesSlice'
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { Audit } from 'component/audit/audit'
import { Tooltip } from '@mui/material';
import { getAPIFilterAsPerRole, checkPermission, getGlobalFilter, getUserDCOption } from 'utils/common.js'
import { DeliveryStatusFilter } from './DeliveryStatusFilter'
import { DeliveryStatusCellRenderer } from './deliveryStatusCellRenderer'
import { RescheduleDelivery } from 'component/returnDashboard/ReschedulePackage'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import BusinessIcon from '@mui/icons-material/Business';
import { SpecialInstrucionsCellRenderer } from 'component/packages/SpecialInstructionsCellRenderer'
import { canViewInternalComments, getDeliveryStatusValue, packagesWithErrorStatus, packagesWithInvestigationStatus } from 'utils/packageHelper'
import { urls } from 'utils/urls'
import { UpdatePackageLocation } from './UpdatePackageLocation'
import { TabWrapper, Tab } from 'component/common/Tab/Tab';
import { SwitchButton } from 'component/common/Input/SwitchButton';
import { UpdateBusiness } from './BusinessHoursOpen';
import useFieldChange from 'hooks/useFieldChange';
import { TableFilter } from 'component/dataTable/tableFilter';
import { handleApplyFilter } from 'utils/common';

const remediationTabEnum = {
    PRACELS_WITH_ERROR: 'PARCELS_WITH_ERROR',
    RTERMINAL_INVTGN: 'RTERMINAL_INVTGN'
}

const formInitialState = {
    trackingNumber: ''
}

export const Remediation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { packages, error, loading, packageCount, updatePackageSuccess, streetPerfectDetails } = useSelector(
        (state) => state?.packages
    )
    const { userProfile, user } = useSelector(
        (state) => state?.auth
    )
    const { sla, highPriority } = useSelector(
        (state) => state?.deliveryStatusManagement
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )

    const [modalProps, setModalProps] = useState(null)
    const [page, setPage] = useState(0);
    const [sorteData, setSortedData] = useState(packages);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [selectedPackage, setSelectcedPackage] = useState(null);
    const [showAddressUpdateModal, setShowAddressUpdateModal] = useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false)
    const [showBusinessModal, setShowBusinessModal] = useState(false)
    const hasEditPermission = checkPermission(user, 'PACKAGES')
    const hasInternalCommentsAccess = canViewInternalComments(user)
    const [selectedTab, setSelectedTab] = useState(remediationTabEnum?.PRACELS_WITH_ERROR);
    const selectedTabRef = useRef();
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [applyFilter, setApplyFilter] = useState(false);




    const fetchPackagesData = () => {
        const filter = getAPIFilterAsPerRole(user, userProfile);
        const globalFilter = getGlobalFilter(highPriority, sla);
        

        const baseFilter = {
            page: page,
            size: rowsPerPage,
            ...filter,
            ...globalFilter,
            dcName: getUserDCOption(user)?.value
        };

        if(formValues?.trackingNumber){
            baseFilter['trackingNumber'] = formValues?.trackingNumber?.trim()
        }

        const additionalFilter = selectedTab === remediationTabEnum.PRACELS_WITH_ERROR
            ? { packageStatus: packagesWithErrorStatus.join(',') }
            : { packageStatus: packagesWithInvestigationStatus?.join(',') };

        dispatch(fetchPackages({
            ...baseFilter,
            ...additionalFilter,
        }));
    };

    useEffect(() => {
        fetchPackagesData()
    }, [page, rowsPerPage, highPriority, sla, selectedTab, applyFilter])

    useEffect(() => {
        return () => {
            dispatch(resetError())
        };
    }, [])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (updatePackageSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Parcel Updated Successfully!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                if (showAddressUpdateModal) {
                    handlePackageEditCick()
                }
                if (showStatusUpdateModal) {
                    handlePackageStatusClick()
                }
                if(showBusinessModal){
                    updateBusinessHoursClick()
                }
                dispatch(resetSuccessState())
                fetchPackagesData()
                setModalProps(null)
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [updatePackageSuccess]);

    useEffect(() => {
        return () => {
            dispatch(clearSelectedPackages());
        };
    }, [dispatch]);

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const trackPackagestatus = (id) => {
        navigate(`${urls?.PARCEL_TRACKING_WIDGET_URL}?tracker-id=${id}`)
    }

    const handlePackageEditCick = (data) => {
        if (!showAddressUpdateModal) {
            setSelectcedPackage(data)
            setShowAddressUpdateModal(true)
        }
        else {
            setSelectcedPackage(null)
            setShowAddressUpdateModal(false)
        }
    }

    const handlePackageStatusClick = (data) => {
        if (!showStatusUpdateModal) {
            dispatch(togglePackageSelection({ packageIds: data?.trackingNumber }))
            setShowStatusUpdateModal(true)
        }
        else {
            setShowStatusUpdateModal(false)
        }
    }

    const updateBusinessHoursClick = (data) => {
        if (!showBusinessModal) {
            dispatch(togglePackageSelection({packageIds : data?.trackingNumber}))
            setShowBusinessModal(true)
        }
        else {
            setShowBusinessModal(false)
        }
    }

    const handleEditDeliveryStatus = (parcel, type, value) => {
        dispatch(markHighPriorityAndSLA({
            trackingNumber: parcel?.trackingNumber,
            type: type,
            value: value
        }))
    }

    const handleOnHold = (parcel, value) => {
        dispatch(markOnHold({
            trackingNumber: parcel?.trackingNumber,
            action: value
        }))
    }

    const handleSwitchToggle = (trackingNumber, checked, channelType) => {
        dispatch(updateNotificationStatus({
            channelType,
            consent: checked ? 'YES' : 'NO',
            trackingNumber
        }))
    };

    const handleApplyFilterClick = () => {
        handleApplyFilter(formValues, formInitialState, setFormErrors, setApplyFilter, setPage, applyFilter);
    }

    const handleResetFilter = () => {
        setFormValues({
            trackingNumber: '',   
        }
        )
        setFormErrors({})
    }

    const columns = [{
        accessor: "packageDetailsId",
        title: "ID",
        width: "2rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.packageDetailsId
    },
    {
        accessor: "trackingNumber",
        title: "Tracking Number",
        width: "3rem",
        Cell: ({ cell: { value } }) => <Link id='trackingNumber' to='/system-routes' sx={{ textDecoration: 'none' }}
            style={iconStyle} onClick={() => trackPackagestatus(value)}> {value}</Link > || '--',
        render: (item) => item?.trackingNumber || '--'
    },
    {
        accessor: 'packageStatus',
        title: "Parcel Status",
        width: "5rem",
        Cell: ({ cell: { value } }) => value ? getConstantValue(packageStatus, value) : '--',
        render: (item) => item?.packageStatus ? getConstantValue(packageStatus, item?.packageStatus) : '--'
    },
    {
        accessor: 'deliveryStatus',
        title: "Attributes",
        width: "5rem",
        isSortable: false,
        Cell: ({ cell: { row: { original } } }) => <DeliveryStatusCellRenderer key={original?.dailyRouteScanSummaryVM?.routeId} allowEditChip={true} isHighPriority={original?.highPriority} isSLA={original?.sla} isHighValueItem={original?.highValueItem} onClick={handleEditDeliveryStatus} parcel={original} handleOnHold={handleOnHold} isOnHold={original?.packageStatus === packageStatusEnum?.ON_HOLD} allowOnHoldEdit={true} />,
        render: (item) => getDeliveryStatusValue(item?.highPriority, item?.sla, item?.highValueItem, item?.onHold, item?.isSignatureRequired)
    },
    {
        accessor: "fsaZone",
        title: "FSA Zone",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.fsaZone || '--'
    },
    {
        accessor: "dcName",
        title: "DC Name",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.dcName || '--'
    },
    {
        accessor: "manifestId",
        title: "Manifest ID",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.manifestId || '--'
    },
    selectedTabRef?.current === remediationTabEnum?.RTERMINAL_INVTGN && {
        accessor: "routeId",
        title: "Route ID",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.routeId || '--'
    },
    {
        accessor: "addressOne",
        title: "Address Line 1",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.addressOne || '--',
        render: (item) => item?.addressOne || '--'
    },
    {
        accessor: "addressTwo",
        title: "Address Line 2",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.addressTwo || '--',
        render: (item) => item?.addressTwo || '--'
    },
    {
        accessor: "postalCode",
        title: "Postal Code",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.postalCode || '--',
        render: (item) => item?.postalCode || '--'
    },
    {
        accessor: "city",
        title: "City",
        width: "6rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.city || '--'
    },
    {
        accessor: "province",
        title: "Province",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.province || '--',
        render: (item) => item?.province || '--'
    },
    {
        accessor: "isBusinessLocationVerified",
        title: "Business Location Verified",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.isBusinessLocationVerified ? 'Yes' : '--',
        render: (item) => item?.isBusinessLocationVerified || '--'
    },
    {
        accessor: "specialInstructions",
        title: "Special Instructions",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.specialInstructions ? <SpecialInstrucionsCellRenderer data={original} type={'specialInstructions'} /> : '--',
        render: (item) => item?.specialInstructions || '--'
    },
    hasInternalCommentsAccess && {
        accessor: "internalComment",
        title: "Internal Comments",
        width: "5rem",
        Cell: ({ cell: { row: { original } } }) => original?.internalComment ? <SpecialInstrucionsCellRenderer data={original} type={'internalComments'} /> : '--',
        render: (item) => item?.internalComment || '--'
    },
    {
        accessor: "consignee",
        title: "Consignee",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.consignee || '--'
    },
    {
        accessor: "createDate",
        title: "Create Date",
        width: "4rem",
        Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
        render: (item) => item?.createDate ? getDate(item?.createDate) : '--'
    },
    {
        accessor: "scheduledDeliveryDate",
        title: "Scheduled Delivery Date",
        width: "4rem",
        Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
        render: (item) => item?.scheduledDeliveryDate ? getDate(item?.scheduledDeliveryDate) : '--'
    },
    hasEditPermission &&
    {
        accessor: "smsNotification",
        title: "SMS Notification",
        width: "4rem",
        isSortable: false,
        Cell: ({ cell: { row: { original } } }) => {
            return (
                <SwitchButton
                    name="optInOut"
                    handleFieldChange={(e) => handleSwitchToggle(original.trackingNumber, e.target.checked, 'SMS')}
                    value={original?.smsConsent === 'AWATING' || original?.smsConsent === 'YES'}
                    width="0rem"
                />
            );
        },
        render: (item) => item?.smsConsent ? 'Opted-in' : 'Opted-out',
    },
    hasEditPermission &&
    {
        accessor: "emailNotification",
        title: "Email Notification",
        width: "4rem",
        isSortable: false,
        Cell: ({ cell: { row: { original } } }) => {
            return (
                <SwitchButton
                    name="optInOut"
                    handleFieldChange={(e) => handleSwitchToggle(original.trackingNumber, e.target.checked, 'EMAIL')}
                    value={original?.emailConsent === 'AWATING' || original?.emailConsent === 'YES'}
                    width="0rem"
                />
            );
        },
        render: (item) => item?.emailConsent ? 'Opted-in' : 'Opted-out',
    },
    hasEditPermission && {
        accessor: 'action',
        title: "Action",
        isSortable: false,
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => <div className='action' >  <Tooltip title="Edit"><img src="/static/images/edit-icon.svg" alt='edit' onClick={() => handlePackageEditCick(original)} /></Tooltip>
            {selectedTabRef.current === 'RTERMINAL_INVTGN' ? <Tooltip title="Update Status"><ChangeCircleIcon style={iconStyle} fontSize="small" onClick={() => handlePackageStatusClick(original)} /></Tooltip> : null}
            <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.trackingNumber, 'entityType': 'package-details' }))} /></Tooltip>
            <Tooltip title="Business Hours"><BusinessIcon style={iconStyle} fontSize="small" onClick={() => updateBusinessHoursClick(original)} /></Tooltip>
        </div>
    }
    ]?.filter(Boolean); // Filter out falsy values (null, undefined, false)

    const tableFilterProps = {
        fields: [
            {
                label: 'Tracking Number',
                value: formValues?.trackingNumber,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '12rem',
                name: 'trackingNumber',
                error: formError?.trackingNumber,
                placeholder: 'Tracking Number'
            },
        ],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px',
    }
    

    const handleModelClose = () => {
        setModalProps(null)
        if (streetPerfectDetails) {
            dispatch(resteStreetPerfectDetails())
        }
    }

    const tabs = [
        {
            value: remediationTabEnum?.PRACELS_WITH_ERROR,
            label: 'Parcels with Error'
        },
        {
            value: remediationTabEnum?.RTERMINAL_INVTGN,
            label: 'Investigate'
        }
    ]

    const handleTabChange = (event, value) => {
        selectedTabRef.current = value
        setSelectedTab(value)
        setFormValues({
            trackingNumber: '',
        })
    }

    return (
        <Layout headerTitle={'Remediation'} globalFilter={<DeliveryStatusFilter />} showBackArrow={false}>
            <TabWrapper>
                <Tab tabs={tabs} handleChange={handleTabChange} value={selectedTab} />
            </TabWrapper>
            <CardDetail >
                <div className='container'>
                <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Remediation' onRefreshClick={fetchPackagesData} />
                <TableFilter {...tableFilterProps}/>
                    <div className='content'>
                        <DataTable columns={columns} data={packages || []} isCollpsable={true} showPagination={packages?.length ? true : false} onPageChange={handlePageChange} page={page} totalRowsCount={packageCount} onSortChange={setSortedData} uniqueKey={'id'} rowKey={'id'} setUpdatedColumns={setUpdatedColumns} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} childComponent={UpdatePackageLocation} />
                    </div>
                </div>
            </CardDetail>
            <Audit />
            {modalProps ? <ResponseModal {...modalProps} handleClose={handleModelClose} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            {showAddressUpdateModal ? <EditPackageAddress isModelOpen={showAddressUpdateModal} handleClose={handlePackageEditCick} selectedPackage={selectedPackage} allowedToStreetPerfect={true}  isRemediationWidget={true}/> : null}
            {showStatusUpdateModal ? <RescheduleDelivery isModelOpen={showStatusUpdateModal} handleClose={handlePackageStatusClick} selectedPackage={selectedPackage} allowedStatuses={remediationPackagestatus} /> : null}
            {showBusinessModal ? <UpdateBusiness isModelOpen={showBusinessModal} handleClose={updateBusinessHoursClick} selectedPackage={selectedPackage} user={user} /> : null}
        </Layout>
    )
}
