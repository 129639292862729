import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

export const ResponseModal = ({ open, title, message,  handleClose, type='success' }) => {
    return (
        <Dialog onClose={handleClose} open={open} >
             <Paper dataTestId="response" style={{ maxWidth: '100%' }}>
            <DialogTitle variat='success' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography dataTestId="reponse-status" color={ type === 'success' ? 'green': 'red'} gutterBottom>
                    {title}
                </Typography>
                <IconButton
                    aria-label="close" dataTestId='repone-close-icon'
                    onClick={handleClose}
                    sx={{
                        left: 10,
                        bottom: 3,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
            <div dataTestId="reponse-message" dangerouslySetInnerHTML={{ __html: message }}>

                {/* <Typography variant="h6" gutterBottom dangerouslySetInnerHTML={{ __html: htmlContent }} > */}
                    {/* {message} */}
                {/* </Typography> */}
                </div>
            </DialogContent>
            </Paper>
        </Dialog >
    );
}