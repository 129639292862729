import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import 'jspdf-autotable';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from "component/common/Button/Button"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'; // Import the export icon
import PrintIcon from '@mui/icons-material/Print';
import { iconStyle, getExportOptions } from 'utils/common'
import { generatePDF, exportToExcel, exportToCSV } from 'utils/exportHelper'

const Export = ({ columns, data, exportFileName }) => {
    const { user } = useSelector(
        (state) => state?.auth
    )

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleExport = (type, columns, data, exportFileName, isPrint) => {
        if (type === 'CSV') {
            exportToCSV(columns, data, exportFileName)
        }
        else if (type === 'EXCEL') {
            exportToExcel(columns, data, exportFileName)
        } else if (type === 'PDF') {
            generatePDF(columns, data, exportFileName, isPrint);
        }
        handlePopoverClose();
    };

    const exportOptions = getExportOptions(user)

    return (
        <>
            <IconButton variant='outlined' dataTestId='export-button' sx={{ borderColor: '#5D48FF' }} startIcon={<CloudDownloadIcon style={iconStyle} />} onClick={handlePopoverOpen}>
                {'Export'}
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {
                    exportOptions?.map(option => {
                        return (<MenuItem key={option} onClick={() => handleExport(option, columns, data, exportFileName)}>Export to {option}</MenuItem>)
                    })
                }
            </Popover>
            <IconButton variant='outlined' dataTestId='print-button' sx={{ borderColor: '#5D48FF' }} startIcon={<PrintIcon style={iconStyle} />} onClick={() => handleExport('PDF', columns, data, exportFileName, true)}>
                {'Print'}
            </IconButton>
        </>
    );
};

export default Export;
