export const validateForm = (formData, notRequiredFields = []) => {
    const errors = {};
    // Iterate over the form data object
    for (const field in formData) {
        if (formData.hasOwnProperty(field)) {
            const value = formData[field];

            // Check if the field is required and has a null value or is empty list or empty object
            const isEmpty = value === null || value === undefined || value === '' ? true :
                Array.isArray(value) ? value.length === 0 :
                    typeof value === 'object' ? Object.keys(value).length === 0 :
                        false;

            if (!notRequiredFields.includes(field) && isEmpty) {
                errors[field] = 'This field is required.';
            }

            //Check if field is string without space
            else if ((['firstName', 'lastName'].includes(field))) {
                const error = validateString(value, false)
                if (error) {
                    errors[field] = error
                }
            }

            //Check if field is string
            else if ((['name', 'province', 'city'].includes(field))) {
                const error = validateString(value)
                if (error) {
                    errors[field] = error
                }
            }

            //Check if field is email
            else if ((['email', 'workEmail', 'personalEmail'].includes(field) && value)) {
                const error = validateEmail(value)
                if (error) {
                    errors[field] = error
                }
            }

            //Check if field is number
            else if ((['capacity'].includes(field))) {
                const error = validateCapacity(value, true)
                if (error) {
                    errors[field] = error
                }
            }

            //Check if field is number
            else if ((['maxStops', 'priority', 'totalParcelLimit'].includes(field))) {
                const error = validateNumber(value)
                if (error) {
                    errors[field] = error
                }
            }

            //Check if field is valide time
            else if ((['totalRouteTime'].includes(field))) {
                const error = validateTime(value, true)
                if (error) {
                    errors[field] = error
                }
            }

            //Check if field is license number
            else if ((['licenseNumber', 'registrationNumber', 'vehicleModel'].includes(field))) {
                const error = validateAlphanumeric(value)
                if (error) {
                    errors[field] = error
                }
            }

            //Check if field is number or float
            else if ((['ratePerHr', 'totalPackages', 'toteCapacity', 'fuelCapacity', 'maxCapacityVolume', 'maxCapacityWeight', 'runningRate', 'deliveryDateBuffer', 'eligibilityDay'].includes(field))) {
                const error = validateNumber(value)
                if (error) {
                    errors[field] = error
                }
            }

            //Check if field is valid phone number or not
            else if ((['workPhone', 'homePhone', 'mobileNumber'].includes(field))) {
                const error = validatePhoneNumber(value)
                if (error) {
                    errors[field] = error
                }
            }

            //Check password validation
            else if ((['tempPassword', 'confirmPassword', 'newPassword'].includes(field) && value)) {
                const error = validatePassword(value)
                if (error) {
                    errors[field] = error
                }
            }

            //Check id validation
            else if ((['routeId', 'manifestId', 'packageId', 'batchId'].includes(field) && value)) {
                const error = validateId(value)
                if (error) {
                    errors[field] = error
                }
            }

        }
    }
    return errors;
}

const validateId = (value) => {
    const regex = /^\s*(0|[1-9]\d*)\s*$/;
    const isValid = regex.test(value);
    if (!isValid) {
        return 'Please enter valid id';
    }
    return null
};

const validateString = (value, allowSpace = true) => {
    const regex = allowSpace ? /^[A-Za-z\s]*$/ : /^[A-Za-z]*$/;
    const isValid = regex.test(value);
    if (!isValid) {
        return 'Only characters are allowed.';
    }
    return null
};

const validateEmail = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regex.test(value.trim());
    if (!isValid) {
        return 'Please enter valid email';
    }
    return null
};


// Custom validation function for start and end dates
export const validateStartAndEndDate = (startDate, endDate) => {
    const error = {}
    // Check if the end date is before the start date
    if (startDate && endDate && (endDate < startDate)) {
        error['startDate'] = "Start Date should be less than End Date";
    }
    return error
}


const validateCapacity = (value, allowNull = false) => {
    if (allowNull && !value) {
        return null
    }
    const regex =  /^[1-9][0-9]*$/;
    const isValid = regex.test(value);
    if (!isValid) {
        return 'Please enter valid number';
    }
    else {
        if (value > 350) {
            return 'Vallue should be between 1 to 350'
        }
    }
    return null
};

const validateTime = (value, allowNull = false) => {
    if (allowNull && !value) {
        return null
    }
    const regex = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
    const isValid = regex.test(value);
    if (!isValid) {
        return 'Please enter valid time';
    }
    return null
};

const validateAlphanumeric = (value) => {
    const regex = /^[a-zA-Z0-9]+$/;
    const isValid = regex.test(value);
    if (!isValid) {
        return 'Please enter valid data';
    }
    return null
};

const validateNumber = (value) => {
    const regex = /^(0|[1-9]\d*(\.\d+)?)$/;
    const isValid = value ? regex.test(value) : true;
    if (!isValid) {
        return 'Please enter valid number';
    }
    return null
};


const validatePhoneNumber = (value) => {
    //One or more digits, spaces, hyphens, or parentheses, An optional plus sign (+) at the beginning
    const regex = /^\+?[1-9]\d{1,14}$|^\+?[0-9\s().-]{7,20}$/;
    const isValid = value ? regex.test(value) : true;
    if (!isValid) {
        return 'Please enter valid contact number';
    }
    return null
};

export const validatePassword = (password) => {
    // Minimum length check
    if (password.length < 8) {
        return 'Password must be at least 8 characters long.';
    }

    // Uppercase letter check
    if (!/[A-Z]/.test(password)) {
        return 'Password must contain at least one uppercase letter.';
    }

    // Lowercase letter check
    if (!/[a-z]/.test(password)) {
        return 'Password must contain at least one lowercase letter.';
    }

    // Number check
    if (!/\d/.test(password)) {
        return 'Password must contain at least one number.';
    }

    // Special character check
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
        return 'Password must contain at least one special character.';
    }

    // No spaces check
    if (/\s/.test(password)) {
        return 'Password must not contain spaces.';
    }

    // If all checks pass, the password is considered strong
    return null;
};

