import React from "react";
import Tote from "./Tote";
import styled from 'styled-components';

const ConveyorRowContainer = styled.div`
display: flex;
justify-content: space-between;
flex-direction: ${(props) => props?.flexDirection || 'row'};
`;

const getSortedData = (data) => {
  // Step 1: Extract keys and sort them
  const sortedKeys = Object.keys(data).sort();

  // Step 2: Create a new object with sorted keys
  const sortedData = {};
  sortedKeys.forEach(key => {
    sortedData[key] = data[key];
  });
  return sortedData
}

const ConveyorRow = ({ row }) => {
  const largeBoxes = getSortedData(row?.largeBoxes)
  return <ConveyorRowContainer flexDirection={row?.flexDirection}>
    {Object.keys(largeBoxes)?.map((largeBoxKey) => {
      const largeBox = row?.largeBoxes?.[largeBoxKey];
      return (
        <Tote key={largeBoxKey} largeBox={largeBox} largeBoxLabel={largeBoxKey} />
      )
    })
    }
  </ConveyorRowContainer>;
}

export default ConveyorRow;
