import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { CustomForm } from 'component/common/Form/CustomForm'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { addManifest } from 'features/manifestManagement/manifestManagementAction'
import { validateForm } from 'utils/formValidator'
import { getDataCentersList, getTenantsList, getClientList } from 'utils/userAccountHelper'
import { resetManifestData } from 'features/manifestManagement/manifestManagementSlice'
import { Loader } from 'component/common/Loader/Loader'
import { getAPIFilterAsPerRole, getUserDCOption } from 'utils/common.js'
import { manifestStatusEnum } from 'constants/manifestStatusConstant';
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';

const formInitialState = {
    shipperName: '',
    bussinessName: '',
    tenantName: '',
    dcName: '',
    manifestFile: null,
    manifestFileName: null,
    createAutoRoutes: false
}

export const AddManifest = ({ isModelOpen, handleClose }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const manifestId = queryParams.get('manifest_id');
    const isEdit = manifestId ? true : false
    const [modalProps, setModalProps] = useState('')
    const { loading, addManifestSuccess } = useSelector(
        (state) => state?.manifestManagement
    )
    const { dataCenters, tenants } = useSelector(
        (state) => state?.userManagement
    )
    const { user, userProfile } = useSelector(
        (state) => state?.auth
    )
    const { clients } = useSelector(
        (state) => state?.clientManagement
    )
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    const filter = getAPIFilterAsPerRole(user, userProfile)
    const isBusinessNameDisabled = filter?.businessName ? true : false


    useEffect(() => {
        // This function will run when the component is unmounting
        return () => {
            dispatch(resetManifestData())
        };
    }, []);

    useEffect(() => {
        if (addManifestSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Manifest created successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                dispatch(resetManifestData())
                handleClose()
                // navigate('/manifest')
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [addManifestSuccess]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result;
                setFormValues((prevValues) => ({
                    ...prevValues,
                    'manifestFile': base64Data?.split(',')?.[1],
                    'manifestFileName': file?.name
                }));
            };

            reader.readAsDataURL(file);
        }
    }

    // const handleCheckboxChange = (event) => {
    //     const { name, checked } = event.target;
    //     setFormValues((prevState) => ({
    //         ...prevState,
    //         [name]: checked,
    //     }));
    // };


    const handleFormSubmit = () => {
        const notRequiredFields = ['createAutoRoutes', 'dcName']
        const errors = validateForm(formValues, notRequiredFields);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const { tenantName, dcName, bussinessName, ...data } = formValues
            //Need to destructure select field values as selected option contains both label and value
            data['tenantName'] = tenantName?.value
            data['dcName'] = getUserDCOption(user)?.value
            data['userName'] = `${user?.firstName} ${user.lastName}`
            data['manifestStatus'] = manifestStatusEnum?.ARRIVED
            data['bussinessName'] = bussinessName?.value
            dispatch(addManifest(data))
        }
    }

    useEffect(() => {
        //Set business name to filter for filtering client data
        if (filter?.businessName) {
            setFormValues((prevValues) => ({
                ...prevValues,
                'bussinessName': { label: filter?.businessName, value: filter?.businessName },
            }));
        }
    }, [filter?.businessName])

    const formProps = {
        fields: [
            {
                label: 'Business Name',
                name: 'bussinessName',
                required: true,
                type: 'select',
                options: getClientList(clients),
                value: formValues?.bussinessName || '',
                handleFieldChange: handleFieldChange,
                error: formError?.bussinessName,
                isDisabled: isBusinessNameDisabled,
                width: '19rem',
                dataTestId : "form-business-name"
            },
            {
                label: 'Shipper Name',
                name: 'shipperName',
                required: true,
                type: 'text',
                value: formValues?.shipperName || '',
                handleFieldChange: handleFieldChange,
                error: formError?.shipperName,
                maxLength: 50,
                dataTestId:'form-shipper-name',
                width: '19rem'
            },
            {
                label: 'Tenant Name',
                name: 'tenantName',
                required: true,
                type: 'select',
                options: getTenantsList(tenants),
                value: formValues?.tenantName || '',
                handleFieldChange: handleFieldChange,
                error: formError?.tenantName,
                dataTestId:'form-tenant-name',
                width: '19rem'
            },
            {
                label: 'DC Name',
                name: 'dcName',
                required: true,
                type: 'select',
                options: getDataCentersList(dataCenters),
                value: getUserDCOption(user),
                handleFieldChange: handleFieldChange,
                error: formError?.dcName,
                width: '19rem',
                dataTestId:'form-dc-name',
                isDisabled: true
            },
            {
                label: 'Manifest File',
                name: 'manifestFile',
                required: true,
                type: 'file',
                value: formValues?.manifestFile || '',
                handleFileUpload: handleFileUpload,
                error: formError?.manifestFile,
                fileName: formValues?.manifestFileName,
                dataTestId:'form-manifest-file',
                width: '19rem'
            },
            // {
            //     type: 'checkbox',
            //     name: 'createAutoRoutes',
            //     label: 'Auto Generate Route',
            //     value: formValues?.createAutoRoutes,
            //     handleChange: handleCheckboxChange,
            // }
        ],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: 'center'
    }

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div data-test-id = "add-manifest-form" className='add-duty-modal'>
                <div><span className='title'>{isEdit ? 'Edit Manifest' : 'Add Manifest'}</span></div>
                <CustomForm {...formProps} />
                {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
                {loading ? <Loader isLoading={true} /> : null}
            </div>
        </CustomModal>
    )
}