import { batchStatusEnum } from 'constants/routeStatus'
import { packageStatusEnum } from 'constants/packageStatus'

export const batchTabEnum = {
    ACCEPT_BATCH: 'ACCEPT_BATCH',
    REJECT_BATCH: 'REJECT_BATCH',
    FINALISE_BATCH: 'FINALISE_BATCH',
    INPROGRESS_BATCH: 'INPROGRESS_BATCH',
    REVERTED_ROUTE: 'REVERTED_ROUTE'
}

export const checkForInProgressBatch = (batches, batchIds) => {
    const inProgressBatchIds = [];
    const allowedBatchIds = [];
    batchIds?.forEach(batchId => {
        const batch = batches?.find(batch => batch?.batchId === parseInt(batchId));
        if (batch && batch?.dailyRouteStatus === batchStatusEnum?.SEQUENCING_INPROGRESS) {
            inProgressBatchIds.push(batchId);
        }
        else {
            allowedBatchIds.push(batchId)
        }
    });
    return { inProgressBatchIds: inProgressBatchIds, allowedBatchIds: allowedBatchIds }
}

export const getTabNames = (batchSummary) => {
    return [
        {
            value: batchTabEnum?.ACCEPT_BATCH,
            label: `Accept : ${batchSummary?.ACCEPT}`
        },
        {
            value: batchTabEnum?.REJECT_BATCH,
            label: `Reject : ${batchSummary?.REJECT}`

        },
        {
            value: batchTabEnum?.FINALISE_BATCH,
            label: `Finalise : ${batchSummary?.FINAISE}`
        },
        {
            value: batchTabEnum?.INPROGRESS_BATCH,
            label: `In Progress : ${batchSummary?.IN_PROGRESS}`
        },
        {
            value: batchTabEnum?.REVERTED_ROUTE,
            label: `Reverted Routes : ${batchSummary?.REVERTED}`
        }
    ]
}

export const isDisabled = (action, status) => {
    if (action === 'accept') {
        return [batchStatusEnum?.BATCH_SUPERVISOR_ACCEPTED, batchStatusEnum?.ROUTE_CREATION_INPROGRESS, batchStatusEnum?.SEQUENCING_INPROGRESS].includes(status)
    }
    if (action === 'reject') {
        return [batchStatusEnum?.ROUTE_CREATION_INPROGRESS].includes(status)
    }
}

export const getUniqueStopCount = (selectedPackages) => {
    const uniqueStopIds = new Set();
    selectedPackages.forEach(pkg => {
        uniqueStopIds.add(pkg.routeStopId);
    });
    return uniqueStopIds?.size;
}

export const getPackageStatusForCreateBatch = () => {
    return [
        {
            label: 'Ready To Batch',
            value: packageStatusEnum?.ORDER_BATCH_READY
        },
        {
            label: 'Parcel eligible for re-batching',
            value: packageStatusEnum?.ORDER_REBATCH_READY
        },
        {
            label: 'Shortage 1',
            value: packageStatusEnum?.SHORTAGE_ONE
        },
        {
            label: 'Shortage 2',
            value: packageStatusEnum?.SHORTAGE_TWO
        }
    ]
}

export const getBatchDetails = (data) => {
    return data?.map((item) => {
        return { label: item?.batchName, value: item?.batchId };
    })
}

export function getPackagesNotInBatch(data, batchId) {
    let packagesNotInBatch = [];
    for (const batch of data) {
        if (batch?.batchId !== batchId) {
            const formattedPackages = batch?.packages?.map(item => ({
                label: item.trackingNumber,
                value: item.trackingNumber
            }));
            packagesNotInBatch = packagesNotInBatch.concat(formattedPackages);
        }
    }
    return packagesNotInBatch;
}
