export const getDataCentersList = (data) => {
    const dataCenters= data && data?.map((item) => {
        return { label: item?.dcName, value: item?.dcName };
    })
    return dataCenters || []
}

export const getTenantsList = (data) => {
    const tenants = data && data.map((item) => {
        return { label: item?.tenantName, value: item?.tenantName };
    })
    return tenants || []
}

export const getClientList = (data) => {
    const customers= data && data?.map((item) => {
        return { label: item?.businessName, value: item?.businessName };
    })
    return customers || []
}