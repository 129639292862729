
import React from 'react'
import { Layout } from 'component/common/Layout/Layout';
import DataTable from 'component/dataTable/dataTable'
import ScheduleJobData from 'data/scheduleJob.json'

export const ScheduleJobs = () => {
    const columns = [{
        accessor: "jobName",
        title: "Scuedule Job Name",
        width: "2rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.jobName
    },
    {
        accessor: "time",
        title: "Running Time",
        width: "2rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.time
    }
    ]

    return (
        <Layout headerTitle={'Schedule Jobs'} showBackArrow={false}>
                <div className='container'>
                    <div className='content'>
                        <DataTable columns={columns} data={ScheduleJobData || []} isCollpsable={false} showPagination={false} uniqueKey={'jobName'} />
                    </div>
                </div>
        </Layout>
    )
}