import React, { useState, useEffect, useRef } from 'react';
import { getMapAccessKey } from "utils/common";
import './mapComponent.scss';
import Chip from '@mui/material/Chip';
import { TabLabel } from 'component/common/Tab/Tab'
import './batchMapView.scss'
const turf = require('@turf/turf');

const H = window.H;

// Replace with your actual HERE Maps API key
const platform = H !== undefined && new H.service.Platform({
  apikey: getMapAccessKey()
});

export const BatchMapView = ({ data, handleMarkerClick }) => {
  const [clickedCoordinates, setClickedCoordinates] = useState([]);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const polylines = useRef([]);
  const [markers, setMarkers] = useState([]);
  const resizablePolygon = useRef(null);
  const isPreviousSelectionExists = useRef(false);

  const createSVGMarkerLabel = (color) => {
    var svgMarkup = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 35"><path id="location_on_FILL1_wght400_GRAD0_opsz48" d="M174-845a58.177,58.177,0,0,1-10.522-11.134Q160-861.275,160-865.65a13.534,13.534,0,0,1,4.222-10.456A13.985,13.985,0,0,1,174-880a13.985,13.985,0,0,1,9.778,3.894A13.534,13.534,0,0,1,188-865.65q0,4.375-3.478,9.516A58.178,58.178,0,0,1,174-845Z" transform="translate(-160 880)" fill="${color}"/></svg>`;
    var icon = new H.map.Icon(svgMarkup);
    return icon;
  }

  const handleMapClick = (event) => {
    if (isPreviousSelectionExists?.current) {
      handleRemoveCoOrdinates()
    }
    if (mapRef.current) {
      const clickedLatLng = mapRef.current.screenToGeo(
        event.currentPointer.viewportX,
        event.currentPointer.viewportY,
      );
      const newCordinate = [clickedLatLng?.lat, clickedLatLng?.lng, 0]
      setClickedCoordinates(prevCoordinates => [...prevCoordinates, newCordinate]);
    }
  }

  function showPostcodes(map) {
    var service = platform.getPlatformDataService();

    // Style for FSA zone boundary
    const fsaZoneStyle = {
      strokeColor: 'blue', // Boundary stroke color
      lineWidth: 2 // Boundary line width
    };

    // create tile provider and layer that displays postcode boundaries
    var boundariesProvider = new H.service.extension.platformData.TileProvider(service,
      {
        layerId: 'PSTLCB_GEN', level: 12
      }, {
      resultType: H.service.extension.platformData.TileProvider.ResultType.POLYLINE,
      styleCallback: function (data) { return fsaZoneStyle }
    });
    var boundaries = new H.map.layer.TileLayer(boundariesProvider);
    map.addLayer(boundaries);

    const svg = `<svg width="50" height="20" xmlns="http://www.w3.org/2000/svg"><text x="0" y="20" font-size="15pt" font-family="Arial">{POSTCODE}</text></svg>`;

    // create tile provider and layer that displays postcode centroids
    var centroidsProvider = new H.service.extension.platformData.TileProvider(service,
      {
        layerId: 'PSTLCB_MP', level: 12
      }, {
      resultType: H.service.extension.platformData.TileProvider.ResultType.MARKER,
      styleCallback(data) {
        const postcode = data.getCell('POSTAL_CODE');
        const postcodeSvg = svg.replace('{POSTCODE}', postcode);
        const icon = new H.map.Icon(postcodeSvg);
        return icon;
      }
    });
    var centroids = new H.map.layer.MarkerTileLayer(centroidsProvider);
    map.addLayer(centroids);
  }

  useEffect(() => {
    const defaultLayers = platform.createDefaultLayers();
    const hereMap = new H.Map(
      mapContainerRef.current,
      defaultLayers.vector.normal.map,
      {
        center: { lat: 50, lng: 24 },
        zoom: 13,
        pixelRatio: window.devicePixelRatio || 1
      }
    );

    //add the minimum possible zoom (furthest away possible zoom)
    defaultLayers.vector.normal.map.setMin(2);

    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener('resize', () => hereMap.getViewPort().resize());

    // const mapEvents = new H.mapevents.MapEvents(hereMap);
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hereMap));
    const ui = H.ui.UI.createDefault(hereMap, defaultLayers);

    // Now use the map as required...
    showPostcodes(hereMap);
    mapRef.current = hereMap;

    // Attach the click event listener to the map
    mapRef.current.addEventListener('tap', handleMapClick);

    return () => {
      return () => {
        behavior.dispose();
        ui.dispose();
        hereMap.dispose();
      };
    };
  }, []);

  const handleRemoveCoOrdinates = () => {
    setClickedCoordinates([])
    // Remove all polylines from the map
    polylines?.current?.forEach(polyline => {
      mapRef.current.removeObject(polyline);
    });

    // // Clear polylines state
    if (resizablePolygon?.current) {
      mapRef?.current?.removeObject(resizablePolygon?.current);
      handleMarkerClick([])
    }
    resizablePolygon.current = null
    polylines.current = [];
    isPreviousSelectionExists.current = false
  }


  useEffect(() => {
    if (clickedCoordinates.length > 1) {
      const data = [].concat(...clickedCoordinates)
      const lineString = new H.geo.LineString(data);
      const newPolyline = new H.map.Polyline(lineString, {
        style: { lineWidth: 10, strokeColor: 'blue' },
      });
      mapRef.current.addObject(newPolyline);
      polylines.current.push(newPolyline)
    }
  }, [clickedCoordinates]);

  useEffect(() => {
    const newMarkerGroup = new H.map.Group();
    if (data?.length && mapRef?.current) {
      const newMarker = [];
      // Create a new marker group and add the marker to it

      mapRef?.current?.addObject(newMarkerGroup);
      let isCoOrdinateExists = false
      data?.forEach((batch) => {
        const icon = createSVGMarkerLabel(batch?.color)
        batch?.packages?.forEach((markerData) => {
          const { lat, lon, trackingNumber: id } = markerData;
          if (lat && lon) {
            isCoOrdinateExists = true
            const marker = new H.map.Marker(
              { lat: lat, lng: lon, id: id },
              { icon: icon }
            );
            marker.setData({ id: id, color: batch?.color, lat: lat, lng: lon, data: markerData });
            newMarker.push(marker);
            newMarkerGroup.addObjects([marker]);
          }
        })
      });
      setMarkers(newMarker)

      if (isCoOrdinateExists) {
        mapRef?.current?.getViewModel().setLookAtData({
          bounds: newMarkerGroup.getBoundingBox()
        });

      }

    }
    return () => {
      newMarkerGroup.removeAll(); // Remove all objects from the group
    };

  }, [data, mapRef]);

  function createResizablePolygon() {
    const data = [].concat(...clickedCoordinates)
    var polygon = new H.map.Polygon(
      new H.geo.Polygon(new H.geo.LineString(data))
    ),

      mainGroup = new H.map.Group({
        volatility: true, // mark the group as volatile for smooth dragging of all it's objects
        objects: [polygon]
      })

    // add group with polygon and it's vertices (markers) on the map
    mapRef?.current?.addObject(mainGroup);
    resizablePolygon.current = mainGroup
    return polygon
  }

  // This function will create a polygon ring from selected co-ordinates
  const getTurfPolygon = (polygon) => {
    const exteriorRingCoords = polygon
      .getGeometry()
      .getExterior()
      .getLatLngAltArray();

    const polygonCoordinates = exteriorRingCoords.reduce((coords, val, idx) => {
      if (idx % 3 === 0) {
        coords.push([val, exteriorRingCoords[idx + 1]]);
      }
      return coords;
    }, []);
    polygonCoordinates.push(polygonCoordinates[0]); // Close the polygon ring
    const turfPolygon = turf.polygon([polygonCoordinates]);
    return turfPolygon
  }

  function isMarkerInsidePolygon(marker, turfPolygon) {
    const markerCoordinates = [marker.getData().lat, marker.getData().lng];
    const turfMarker = turf.point(markerCoordinates);
    return turf.booleanPointInPolygon(turfMarker, turfPolygon);
  }

  function filterMarkersInsidePolygon(markers, polygon) {
    const turfPolygon = getTurfPolygon(polygon)
    const markersInsidePolygon = markers.filter(marker =>
      isMarkerInsidePolygon(marker, turfPolygon)
    );

    return markersInsidePolygon;
  }

  const getMarkersInsidePolyline = () => {
    if (!resizablePolygon.current && polylines?.current?.length > 1) {
      isPreviousSelectionExists.current = true
      const polygon = createResizablePolygon();
      const markersInsidePolygon = filterMarkersInsidePolygon(markers, polygon);
      if (markersInsidePolygon?.length) {
        const markerData = markersInsidePolygon.map((marker) => marker.getData()?.data)
        handleMarkerClick(markerData)
      }
    }
  }

  return (
    <div className="Wrapper">
      <div className="ChipWrapper">
        {data.map((batch) => (
          <Chip
            key={batch?.batchId}
            label={<TabLabel label={batch?.batchName} count={batch?.packages?.length} time={batch?.routeServiceTimeInHr} />}
            sx={{ backgroundColor: batch?.color, color: 'white', height: '50px' }}
          />
        ))}
      </div>
      <div className="MapWrapper">
        <button className="custom-button"
          onClick={handleRemoveCoOrdinates}>Clear</button>
        <button className="custom-button-final" onClick={getMarkersInsidePolyline}>Final</button>
        <div className="Container" ref={mapContainerRef} />
      </div>
    </div>
  );
}