import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { parseNotificationBody, getRedirectURL } from 'utils/notificationUtils'
import './Layout.scss'

export const NotificationPanel = ({ open, onClose }) => {
    const navigate = useNavigate();
    const { messages } = useSelector(
        (state) => state?.messages
    )

    const handleAnchorClick = (event, data) => {
        event.preventDefault()
        const url = getRedirectURL(data)
        if (url) {
            navigate(url)
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} className='notification-drawer'
        >
            <Box sx={{ width: 250 }} onClick={onClose} onKeyDown={onClose}>
                <List>
                    {messages?.map((msg, index) => {
                        const parsedJson = parseNotificationBody(msg);
                        return (
                            <React.Fragment key={`${index}-${parsedJson?.title}`}>
                                <ListItem>
                                    <ListItemText primary={parsedJson?.title}
                                        secondary={<span dangerouslySetInnerHTML={{ __html: parsedJson?.body?.message }} onClick={(event) => handleAnchorClick(event, msg.body)} />}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
                </List>
            </Box>
        </Drawer>
    );
};