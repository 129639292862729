import React, { useEffect, useRef, useState } from 'react';
import { getMapAccessKey } from "utils/common";
import './MapLocation.scss';

const MapLocation = ({ initialLat, initialLng, onMarkerDragEnd }) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const logRef = useRef(null);
    const [initialized, setInitialized] = useState(false);
    const H = window.H;

    const createSVGMarkerLabel = (color) => {
        var svgMarkup = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 28 35"><path id="location_on_FILL1_wght400_GRAD0_opsz48" d="M174-845a58.177,58.177,0,0,1-10.522-11.134Q160-861.275,160-865.65a13.534,13.534,0,0,1,4.222-10.456A13.985,13.985,0,0,1,174-880a13.985,13.985,0,0,1,9.778,3.894A13.534,13.534,0,0,1,188-865.65q0,4.375-3.478,9.516A58.178,58.178,0,0,1,174-845Z" transform="translate(-160 880)" fill="${color}"/></svg>`;
        var icon = new H.map.Icon(svgMarkup);
        return icon;
    }

    useEffect(() => {

        // Initialize the platform with your HERE API key
        const platform = new H.service.Platform({
            apikey: getMapAccessKey(),
        });

        // Create the default layers
        const defaultLayers = platform.createDefaultLayers();

        // Initialize the map
        const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
            center: { lat: initialLat, lng: initialLng },
            zoom: 14,
            pixelRatio: window.devicePixelRatio || 1,
        });

        // Enable the event system on the map instance
        const mapEvents = new H.mapevents.MapEvents(map);

        // Add map behavior (pan/zoom/drag)
        const behavior = new H.mapevents.Behavior(mapEvents);

        // Create a marker
        const icon = createSVGMarkerLabel('#5D48FF')

        const marker = new H.map.Marker(
            { lat: initialLat, lng: initialLng },
            { icon: icon },
            { volatility: true }
          );

        marker.draggable = true;
        map.addObject(marker);
        markerRef.current = marker;

        // Create custom logging facilities
        const logContainer = document.createElement('div');
        logContainer.className = 'log';
        logContainer.innerHTML = createLogEntry(initialLat, initialLng);
        map.getElement().appendChild(logContainer);
        logRef.current = logContainer;

        // Add event listeners for marker drag events
        map.addEventListener('dragstart', (ev) => {
            const target = ev.target;
            if (target instanceof H.map.Marker) {
                behavior.disable();
            }
        });

        map.addEventListener('drag', (ev) => {
            const target = ev.target;
            const pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
                target.setGeometry(map.screenToGeo(pointer.viewportX, pointer.viewportY));
            }
        });

        map.addEventListener('dragend', (ev) => {
            const target = ev.target;
            if (target instanceof H.map.Marker) {
                behavior.enable();
                const newPosition = target.getGeometry();
                updateLog(newPosition.lat, newPosition.lng);
                if (initialized) {
                    onMarkerDragEnd({ lat: newPosition.lat, lng: newPosition.lng });
                }
            }
        });

        // Attach input event listeners after initial render
        attachInputEventListeners();

        // Set initialized flag to true after first render
        setInitialized(true);

        // Cleanup on component unmount
        return () => {
            map.dispose();
        };
    }, []);

    useEffect(() => {
        if (markerRef.current && initialized) {
            markerRef.current.setGeometry({ lat: initialLat, lng: initialLng });
            updateLog(initialLat, initialLng);
        }
    }, [initialLat, initialLng, initialized]);

    const createLogEntry = (lat, lng) => {
        return `
            <div class="log-entry">
                Lat: <input type="text" value="${parseFloat(lat)?.toFixed(6)}" class="lat-input" />
                Lng: <input type="text" value="${parseFloat(lng)?.toFixed(6)}" class="lng-input" />
                <button class="submit-btn" style="margin-top: 5px;">Submit</button>
            </div>`;
    };

    const updateLog = (lat, lng) => {
        if (lat && lng && logRef.current) {
            logRef.current.innerHTML = createLogEntry(lat, lng);
            attachInputEventListeners();
        }
    };

    const attachInputEventListeners = () => {
        const submitButton = logRef.current.querySelector('.submit-btn');
        submitButton.addEventListener('click', handleSubmit);
    };

    const handleSubmit = () => {
        const newLat = parseFloat(logRef.current.querySelector('.lat-input').value);
        const newLng = parseFloat(logRef.current.querySelector('.lng-input').value);

        if (!isNaN(newLat) && !isNaN(newLng)) {
            updateMarkerAndLog(newLat, newLng);
        }
    };

    const updateMarkerAndLog = (lat, lng) => {
        if (markerRef.current) {
            markerRef.current.setGeometry({ lat, lng });
            updateLog(lat, lng);
            onMarkerDragEnd({ lat, lng });
        }
    };

    return <div ref={mapRef} style={{ width: '100%', height: '500px' }} />;
};

export default MapLocation;
