import { APPEND_MESSAGE } from './messageAction';

const initialState = {
    messages: [
    ]
};

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case APPEND_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.payload]
            };
        default:
            return state;
    }
};

export default messageReducer;