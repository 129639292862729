import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { Loader } from 'component/common/Loader/Loader'
import { updatePackage } from 'features/packages/packagesAction'
import 'component/dailyRoaster/AddDuty.scss'
import { CustomForm } from 'component/common/Form/CustomForm'
import { allowPackageEdit, packagesWithDeliveredStatus, canViewInternalComments } from 'utils/packageHelper'
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';

const formInitialState = {
    addressOne: '',
    addressTwo: '',
    city: '',
    postalCode: '',
    province: '',
    specialInstructions: '',
    internalComment: '',
    sendToStreetPerfect: false,
    email: '',
    mobileNumber: ''
}

export const EditPackageAddress = ({ isModelOpen, handleClose, selectedPackage, allowedToStreetPerfect, isRemediationWidget = false }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const { loading } = useSelector(
        (state) => state?.packages
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const [allowEdit, setAllowEdit] = useState(true)
    const allowSpecialInstructionEdit = !packagesWithDeliveredStatus?.includes(selectedPackage?.packageStatus)
    const hasInternalCommentsAccess = canViewInternalComments(user)

    useEffect(() => {
        if (selectedPackage) {
            setFormValues({
                addressOne: selectedPackage?.addressOne,
                addressTwo: selectedPackage?.addressTwo,
                city: selectedPackage?.city,
                postalCode: selectedPackage?.postalCode,
                province: selectedPackage?.province,
                specialInstructions: selectedPackage?.specialInstructions,
                internalComment: selectedPackage?.internalComment,
                sendToStreetPerfect: selectedPackage?.sendToStreetPerfect,
                email: selectedPackage?.email,
                mobileNumber: selectedPackage?.mobileNumber
            });
            const isEditAllowed = allowPackageEdit(selectedPackage?.packageStatus, user)
            setAllowEdit(isEditAllowed)
        }
    }, [selectedPackage]);

    const handleFormSubmit = () => {
        setFormErrors({})
        const notRequiredFields = ['specialInstructions', 'addressTwo', 'internalComment', 'sendToStreetPerfect', 'email', 'mobileNumber']
        const errors = validateForm(formValues, notRequiredFields);
        if (!Object.keys(errors).length) {
            dispatch(updatePackage({ ...formValues, trackingNumber: selectedPackage?.trackingNumber, sendToStreetPerfect: formValues?.sendToStreetPerfect || false }))
        }
        else {
            setFormErrors(errors)
        }
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };


    const formProps = {
        fields: [{
            label: 'Address Line 1',
            name: 'addressOne',
            required: true,
            type: 'text',
            value: formValues?.addressOne || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            disabled: !allowEdit,
            error: formError?.addressOne,
        },
        {
            label: 'Address Line 2',
            name: 'addressTwo',
            required: false,
            type: 'text',
            value: formValues?.addressTwo || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            disabled: !allowEdit,
            error: formError?.addressTwo,
        },
        {
            label: 'City',
            name: 'city',
            required: true,
            type: 'text',
            value: formValues?.city || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            disabled: !allowEdit,
            error: formError?.city,
        },
        {
            label: 'Province',
            name: 'province',
            required: true,
            type: 'text',
            value: formValues?.province || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            disabled: !allowEdit,
            error: formError?.province,
        },
        {
            label: 'Postal Code',
            name: 'postalCode',
            required: true,
            type: 'text',
            value: formValues?.postalCode || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            disabled: !allowEdit,
            error: formError?.postalCode,
        },
        {
            label: 'Consginee Email',
            name: 'email',
            type: 'email',
            value: formValues?.email || '',
            handleFieldChange: handleFieldChange,
            error: formError?.email,
            maxLength: 254,
            width: '19rem',
            disabled: !isRemediationWidget
        },
        {
            label: 'Consginee Number',
            name: 'mobileNumber',
            type: 'text',
            value: formValues?.mobileNumber || '',
            handleFieldChange: handleFieldChange,
            error: formError?.mobileNumber,
            maxLength: 30,
            width: '19rem',
            disabled: !isRemediationWidget
        },
        {
            label: 'Special Instructions',
            name: 'specialInstructions',
            required: false,
            type: 'text',
            value: formValues?.specialInstructions || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            error: formError?.specialInstructions,
            disabled: !allowSpecialInstructionEdit
        },
        hasInternalCommentsAccess && {
            label: 'Internal Comments',
            name: 'internalComment',
            required: false,
            type: 'textarea',
            value: formValues?.internalComment || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            error: formError?.internalComment,
            maxRows: 3,
            minRows: 3
        },
        allowedToStreetPerfect && {
            type: 'checkbox',
            options: { sendToStreetPerfect: 'Send To Street Perfect' },
            value: formValues,
            handleChange: handleCheckboxChange,
            width: '19rem',
        },
        ],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: 'left'
    }

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div className='add-duty-modal'>
                <div><span className='title'>Edit Address</span></div>
                <CustomForm {...formProps} />
                {loading ? <Loader isLoading={true} /> : null}
            </div>
        </CustomModal>
    )

}