import { environment } from "../environments/environment.jsx";
import axios from "axios";
import { getHeader } from './authUtils.js'

const apiEndpoint = environment.webservices.endpoint;
export class EventServive{
    getAllEndToEndEvents(){
        const endpoint = `${apiEndpoint}/api/end-to-end-events`;
        return axios.get(endpoint, {
          headers: getHeader(),
        });
    }
    getEndToEndEvents(id){
        const endpoint = `${apiEndpoint}/api/end-to-end-events/${id}`;
        return axios.get(endpoint, {
          headers: getHeader(),
        });
    }
    getPublicEventsByTrackingNumber(trackingNumber, postalCode){
        const endpoint = `${apiEndpoint}/api/track`;
        const accessToken = localStorage.getItem('token');

        var data = JSON.stringify({
            "postalCode": postalCode,
            "trackingNumber": trackingNumber
          });
        return axios.post(endpoint, data, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
        });
    }
}