import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { SelectField } from 'component/common/Input/SelectField'
import { Button } from '@mui/material';
import { validateForm } from 'utils/formValidator'
import { getDriverList, getVehicles } from 'utils/common'
import { addRoaster, fetchDailyRoasterDetails } from 'features/dailyRoaster/dailyRoasterAction'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { resetSuccessData } from 'features/dailyRoaster/dailyRoasterSlice'
import { Loader } from 'component/common/Loader/Loader'
import { fetchVehiclesWithoutDrivers } from 'features/vehicle/vehicleAction'
import { fetchDrivers } from 'features/driverManagement/driverManagementAction'
import { CustomModal } from 'component/common/Modal/CustomModal'
import './AddDuty.scss'

const formInitialState = {
    driver: '',
    vehicle: '',
}

export const AddDuty = ({ isModelOpen, handleClose, roasterId }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [disableVehicle, setVehicleDisable] = useState(false);
    const [modalProps, setModalProps] = useState(null)

    const { drivers, loading: driversLoading } = useSelector(
        (state) => state?.driverManagement
    )
    const { vehiclesWithoutDrivers, loading: vehiclesLoading } = useSelector(
        (state) => state?.vehicles
    )

    const { dailyRoasterDetails, loading,  addRoasterSuccess, error } = useSelector(
        (state) => state?.dailyRoaster
    )

    useEffect(() => {
        dispatch(fetchVehiclesWithoutDrivers({widget: 'roaster'}))
        dispatch(fetchDrivers({
            page: 0,
            size: 500,
            unassigned: true,
            inServiceStatus: 'ACTIVE'
        }))
    }, [])

    // useEffect(() => {
    //     if (formValues?.driver) {
    //         dispatch(fetchDailyRoasterRoutes(formValues?.driver?.value))
    //     }
    // }, [formValues?.driver])

    useEffect(() => {
        if (roasterId) {
            dispatch(fetchDailyRoasterDetails(roasterId))
        }
    }, [roasterId])

    
    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: 'error'
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (addRoasterSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: roasterId ? 'Roaster updated successfully' : 'Roaster created successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                handleClose()
                dispatch(resetSuccessData())
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [addRoasterSuccess]);

    useEffect(() => {
        if (dailyRoasterDetails) {
            setFormValues({
                driver: { label: dailyRoasterDetails?.driverName, value: dailyRoasterDetails?.driverId },
                vehicle: { label: dailyRoasterDetails?.vehicle, value: dailyRoasterDetails?.vehicleId }
            });
            // Check if driver has vehicle
            if (dailyRoasterDetails?.driver?.vehicle) {
                setVehicleDisable(true)
            }
        }
    }, [dailyRoasterDetails])

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        if (name === 'driver') {
            if (value?.vehicle) {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    vehicle: { label: value?.vehicle?.registrationNumber, value: value?.vehicle?.id, ...value?.vehicle },
                }));
                //If driver is having vehicle then user should not be able to select vehicle
                setVehicleDisable(true)
            }
            //This is needed because when se;ect driver with vehicle and then select any other driver
            else {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    vehicle: '',
                }));
                setVehicleDisable(false)
            }
        }
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };


    const handleFormSubmit = () => {
        const errors = validateForm(formValues);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const data = {}
            const { driver, vehicle } = formValues
            data['driverId'] = driver?.value
            data['vehicalId'] = vehicle?.value
            dispatch(addRoaster(data))
        }
    }

    const handleCancel = () => {
        setFormValues(formInitialState)
        dispatch(resetSuccessData())
        handleClose()
    }

    return (
        <>
            <CustomModal
                open={isModelOpen}
                onClose={handleCancel}
            >
                <div className='add-duty-modal'>
                    <span className='title'>{'Add Duty'}</span>
                    <div>
                        <SelectField label='Driver' name='driver' options={getDriverList(drivers)} width={'18rem'}
                            value={formValues?.driver} handleFieldChange={handleFieldChange} error={formError?.driver} isLoading={driversLoading} required={true}/>
                    </div>
                    <div>
                        <SelectField label='Vehicle' name='vehicle' options={getVehicles(vehiclesWithoutDrivers, false)}
                            width={'18rem'} value={formValues?.vehicle}
                            handleFieldChange={handleFieldChange}
                            error={formError?.vehicle} isDisabled={disableVehicle} isLoading={vehiclesLoading} required={true} />
                    </div>
                    <div className='button-container'>
                        <Button onClick={handleFormSubmit} variant='outlined'>Save</Button>
                        <Button onClick={handleCancel} variant='outlined'>Cancel</Button>
                    </div>

                </div>
            </CustomModal>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </>

    )

}