import React from 'react';
import { TablePagination as MUITablePagination } from '@mui/material';

export const TablePagination = ({ onPageChange, page, rowsCount, handleChangeRowsPerPage, rowsPerPage }) => {
    // Calculate the available rows per page options
    const availableRowsPerPageOptions = Array.from({ length: Math.ceil(rowsCount / 10) }, (_, i) => (i + 1) * 10);

    return (
        <MUITablePagination component="div"   data-testid={`table-pagination`}

            count={parseInt(rowsCount, 10)} onPageChange={onPageChange} page={page} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} rowsPerPageOptions={availableRowsPerPageOptions} />
    );
};