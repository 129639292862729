import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import { fetchUsers, fetchDataCenters, fetchTenants } from 'features/userManagement/userManagementAction'
import { useNavigate } from "react-router-dom";
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { getDateWithTimeStamp, formatDate, iconStyle, checkPermission, getUserDCOption, handleApplyFilter } from 'utils/common'
import { CardDetail } from 'component/dashboard/cardDetail'
import { PrimaryButton } from 'component/common/Button/Button.jsx'
import { TableHeader } from 'component/dataTable/tableHeader';
import { getTenantsList, getDataCentersList } from 'utils/userAccountHelper'
import { roles } from 'constants/roleConstants'
import { statuses } from 'constants/userStatusConstants'
import { TableFilter } from 'component/dataTable/tableFilter';
import Avatar from '@mui/material/Avatar';
import { Tooltip } from '@mui/material';
import { Audit } from 'component/audit/audit'
import './UserAccount.scss'
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';

const formInitialState = {
    name: '',
    email: '',
    tenantName: '',
    role: '',
    createdDate: '',
    activated: '',
    dcName: ''
}

const AvatarWithName = ({ profile_pic, firstName, lastName }) => {
    const photo = profile_pic ? `data:image\/(png|jpeg|jpg);base64,${profile_pic}` : null
    return (<div style={{ display: "flex", alignItems: "center", gap: '10px' }}>
        <Avatar sx={{ width: 30, height: 30 }} alt={firstName} src={photo} variant="square" />
        <span>{`${firstName || "-"} ${lastName || "-"}`}</span>
    </div>)
};

export const UserAccount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { users, error, loading, userCount, tenants, dataCenters } = useSelector(
        (state) => state?.userManagement
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const [modalProps, setModalProps] = useState(null)
    const [sorteData, setSortedData] = useState(users);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [page, setPage] = useState(0);
    const [applyFilter, setApplyFilter] = useState(false);
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const hasEditPermission = checkPermission(user, 'USER MANAGEMENT')

    useEffect(() => {
        const data = {}

        if (formValues?.name) {
            data['name'] = formValues?.name?.trim()
        }
        if (formValues?.email) {
            data['email'] = formValues?.email?.trim()
        }
        if (formValues?.tenantName?.value) {
            data['tenantName'] = formValues?.tenantName?.value
        }
        if (formValues?.role?.value) {
            data['role'] = formValues?.role?.value || null
        }
        if (formValues?.activated?.value) {
            data['activated'] = formValues?.activated?.value || null
        }
        if (formValues?.createdDate) {
            data['createdDate'] = formatDate(formValues?.createdDate) || null
        }
        data['dcName'] = getUserDCOption(user)?.value
        // if (formValues?.dcName?.value) {
        //     data['dcName'] = formValues?.dcName?.value || null
        // }
        dispatch(fetchUsers({
            page: page,
            size: rowsPerPage,
            ...data,
        }))
    }, [page, applyFilter, rowsPerPage])

    useEffect(() => {
        dispatch(fetchDataCenters())
        dispatch(fetchTenants())
    }, [])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
        else {
            // No error, close modal or reset modal props
            setModalProps({
                title: '',
                message: '',
                open: false,
                type: ''
            });
        }
    }, [error]);

    const handleAddUserClick = () => {
        navigate(urls?.ADD_USER_WIDGET_URL)
    }


    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleEditUserClick = (id) => {
        navigate(`${urls?.EDIT_USER_WIDGET_URL}?user_name=${id}`)
    }

    const get_role = (role) => {
        const userRole =  roles?.find(userRole => userRole?.value === role)
        return userRole?.label
    }

    const handleApplyFilterClick = () => {
        handleApplyFilter(formValues, formInitialState, setFormErrors, setApplyFilter, setPage, applyFilter);
    }

    const handleResetFilter = () => {
        setFormValues({
            name: '',
            email: '',
            tenantName: '',
            role: '',
            createdDate: '',
            activated: '',
            dcName: ''
        })
        setFormErrors({})
    }

    const columns = [{
        accessor: "firstName",
        title: "Name",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => <AvatarWithName profile_pic={original?.userPhoto} firstName={original?.firstName} lastName={original?.lastName} />,
        render: (item) => `${item?.firstName} ${item?.lastName}`
    },
    {
        accessor: "email",
        title: "Email",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.email || '--'
    },
    {
        accessor: "login",
        title: "User Name",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.login || '--'
    },
    {
        accessor: 'dcName',
        title: "DC",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.dcName || '--'
    },
    {
        accessor: 'tenantName',
        title: "Tenant Name",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.tenantName || '--'
    },
    {
        accessor: 'authorities',
        title: "Role",
        width: "2rem",
        Cell: ({ cell: { value } }) => get_role(value?.[0]) || '--',
        render: (item) => get_role(item?.authorities?.[0]) || '--'
    },
    {
        accessor: 'createdBy',
        title: "Created By",
        width: "3rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.createdBy || '--'
    },
    {
        accessor: 'createdDate',
        title: "Created Date",
        width: "3rem",
        Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
        render: (item) => item?.createdDate ? getDateWithTimeStamp(item?.createdDate, user) : '--'
    },
    {
        accessor: 'activated',
        title: "Active",
        width: "2rem",
        Cell: ({ cell: { value } }) => value ? 'Yes' : 'No',
        render: (item) => item?.activated ? 'Yes' : 'No'
    },
    hasEditPermission && {
        accessor: 'action',
        title: "Action",
        width: "1rem",
        isSortable: false,
        Cell: ({ cell: { row: { original } } }) => <div className='action'><Tooltip title="Edit"><img src="static/images/edit-icon.svg" alt='edit' onClick={() => handleEditUserClick(original?.login)} /></Tooltip>
            <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.id, 'entityType': 'users' }))} /></Tooltip>
        </div>
    }]?.filter(Boolean)

    const tableFilterProps = {
        fields: [{
            label: 'Name',
            value: formValues?.name,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '12rem',
            name: 'name',
            error: formError?.name,
            placeholder: 'Name'
        },
        {
            label: 'Email',
            value: formValues?.email,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '12rem',
            name: 'email',
            error: formError?.email,
            placeholder: 'Email'
        },
        {
            label: 'Tenant Name',
            value: formValues?.tenantName,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '12rem',
            options: getTenantsList(tenants),
            name: 'tenantName'
        },
        {
            label: 'DC',
            value: getUserDCOption(user),
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '12rem',
            options: getDataCentersList(dataCenters),
            name: 'dcName',
            isDisabled: true
        },
        {
            label: 'Role',
            value: formValues?.role,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '12rem',
            options: roles,
            name: 'role'
        },
        {
            label: 'Created Date',
            value: formValues?.createdDate,
            handleFieldChange: handleFieldChange,
            type: 'date',
            width: isMobile ? '9rem' : '12rem',
            name: 'createdDate',
            placeholder: 'DD-MM-YYYY',
            error: formError?.createdDate
        },
        {
            label: 'Active',
            value: formValues?.activated,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '12rem',
            options: statuses,
            name: 'activated'
        }],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px',
    }

    return (
        <Layout headerTitle={'Users'} showBackArrow={false} action={hasEditPermission ? <div><PrimaryButton type="button" variant='primary' label={'Add User'} height={'30px'} onClick={handleAddUserClick} /></div> : null}
        >
            <CardDetail>
                <div className='container'>
                    <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Users'  onRefreshClick={handleApplyFilterClick}/>
                    <TableFilter {...tableFilterProps} />
                    <div className='content'>
                        <DataTable columns={columns} data={users || []} isCollpsable={false} onSortChange={setSortedData} uniqueKey={'id'} setUpdatedColumns={setUpdatedColumns} showPagination={users?.length ? true : false} onPageChange={handlePageChange} page={page} totalRowsCount={userCount} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
                    </div>
                </div>
            </CardDetail>
            <Audit />
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}