import React from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
  height: 25px;
  border: 1px solid #d5d5d5;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
`;

const ProgressColor = styled.div`
  height: 100%;
  background-color: ${({ color }) => color};
  width: ${({ percentage }) => percentage}%;
  transition: width 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
`;

const Text = styled.span`
  font-family: "degular", sans-serif;
  letter-spacing: 0.43px;
  color: black;
  opacity: 1;
  font-size: 10px;
  color: #999999;
`;

const ProgressImage = styled.div`
  height: 100%;
  background-image: ${({ backgroundImage }) => backgroundImage};
  background-size: cover;
  width: ${({ imagePercentage }) => imagePercentage}%;
  transition: width 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
`;
export const ProgressBar = ({
  totalStops,
  completedStops,
  remainingStops,
  timeLeft,
  color,
  backgroundImage,
  showText = true,
  backgroundImagePercent,
  colorPercent,
}) => {
  return (
    <>
      <ProgressBarContainer>
        <ProgressColor percentage={colorPercent} color={color} />
        {backgroundImage ? (
          <ProgressImage
            imagePercentage={backgroundImagePercent}
            backgroundImage={backgroundImage}
          />
        ) : null}
      </ProgressBarContainer>
      {showText && (<Text>{completedStops}/{totalStops} ({remainingStops} Behind) {timeLeft}{" "}Left</Text>)}
    </>
  );
};