import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { useLocation } from "react-router-dom";
import { getRoute } from 'features/route/routeAction'
import './routeDetailsComponent.scss'
import { RouteMapComponent } from './routeMapComponent';
import { RouteParcels } from './routeParcel'
import './routeDetailsComponent.scss'

const HEIGHT = '24rem'

export const RouteDetails = React.memo(({ id }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const routeId = queryParams.get('routeId');

    const dispatch = useDispatch();
    const { data: routeData, routes } = useSelector(
        (state) => state.route
    )

    const [selectedOption, handleOptionChange] = useState('map')

    const routeDetail = routeId ? routes : routeData[id]

    useEffect(() => {
        if (!routeId) {
            dispatch(getRoute(id))
        }
    }, [])

    return (
        <>
            <FormControl component="fieldset" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <RadioGroup
                    row
                    aria-label="options"
                    name="options"
                    value={selectedOption}
                    onChange={(event) => handleOptionChange(event.target.value)}
                >
                    <FormControlLabel
                        key={'map'}
                        value={'map'}
                        control={<Radio />}
                        label={<span className='text'>Show Map</span>}
                    />
                    <FormControlLabel
                        key={'table'}
                        value={'table'}
                        control={<Radio />}
                        label={<span className='text'>Show parcels</span>}
                    />
                </RadioGroup>
            </FormControl>
            {selectedOption === 'map' ?
                <RouteMapComponent routeDetail={routeDetail} height={HEIGHT} />
                : <RouteParcels routeDetail={routeDetail} height={HEIGHT} />}
        </>
    )
})