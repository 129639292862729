import React from 'react';
import styled from 'styled-components';
import { StyledLabel, ErrorMessage, FieldWrapper } from './SelectField'
import StarIcon from '@mui/icons-material/Star';
import { TextareaAutosize } from '@mui/material';

const StyledTextField = styled(TextareaAutosize)`
  width: ${props => props.width || '14rem'};
  height: 40px;
  border: 1px solid #E4E4E4;
  border-radius: 7px;
  padding: 10px;
  color: ${props => props.color || '#000000'};
  cursor: pointer;
  resize: none;
  font-size: 14px;
  font-family: 'Degular', sans-serif;

  &::placeholder {
    color: #999;
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border-color: #aaa;
    box-shadow: none;
    cursor: pointer;
  }
`;


export const TextArea = ({ label, lableColor, error, handleFieldChange, placeholder, required, width, ...rest }) => {
    return (
        <FieldWrapper width={width}>
            <StyledLabel lablecolor={lableColor} data-testid={`label-${label}`}>{label}
                {required && <StarIcon style={{ color: 'red', fontSize: '8px', verticalAlign: 'middle', marginLeft: '5px' }} />}
            </StyledLabel>
            <StyledTextField width={width} data-testid={`name-${rest?.name}`} onChange={handleFieldChange} placeholder={placeholder} {...rest}
            />
            {error && <ErrorMessage width={width}>{error}</ErrorMessage>}
        </FieldWrapper>
    );
};