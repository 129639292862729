import { createSlice } from '@reduxjs/toolkit';
import { fetchManifests, addManifest, fetchBusinessDetails } from './manifestManagementAction'
import { logout } from 'features/auth/authAction'

const initialState = {
    manifests: [],
    loading: false,
    error: null,
    manifestDetails: null,
    addManifestSuccess: false,
    manifestCount: 0,
    businessNames: [],
    businessNamesLoading: false,
}

// Redux slice for manifests
const manifestsManagementSlice = createSlice({
    name: 'manifestsManagementSlice',
    initialState,
    reducers: {
        resetManifestData: (state) => {
            state.addManifestSuccess = false;
            state.error = null
            state.loading = null
        }
    },
    extraReducers: (builder) => {
        builder
            // manifest Slice
            .addCase(fetchManifests.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchManifests.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.manifests = action?.payload?.manifests
                state.manifestCount = action?.payload?.totalCount
            })
            .addCase(fetchManifests.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // add manifest Slice
            .addCase(addManifest.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addManifest.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.addManifestSuccess = true
            })
            .addCase(addManifest.rejected, (state, action) => {
                state.loading = false;                
                state.error = action?.payload;
            })

            .addCase(fetchBusinessDetails.pending, (state) => {
                state.businessNamesLoading = true;
                state.error = null;
              })
              .addCase(fetchBusinessDetails.fulfilled, (state, action) => {
                state.businessNamesLoading = false;
                state.businessNames = action?.payload
              })
              .addCase(fetchBusinessDetails.rejected, (state, action) => {
                state.businessNamesLoading = false;
                state.error = action.payload;
              })
            
            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })
    }
});

export const { resetManifestData } = manifestsManagementSlice.actions;
export default manifestsManagementSlice.reducer;