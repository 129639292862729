import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'component/dataTable/dataTable'
import { Loader } from 'component/common/Loader/Loader'
import { fetchAuditData } from 'features/audit/auditAction'
import { getDateWithTimeStamp } from 'utils/common'
import { resetAuditData } from 'features/audit/auditSlice'
import 'component/userManagement/userAccount/UserAccount.scss'
import { Modal, Drawer } from '@mui/material';
import styled from 'styled-components';

const Wrapper = styled.div`
//   max-height: ${props => props?.isMobile ? '300px' : '400px'};
  overflow: auto;
  width : inherit;
  padding: 1rem 0rem;
  background: #ffffff;
`;

export const Audit = () => {
    const dispatch = useDispatch();
    const { entityType, entityId, auditData, loading } = useSelector(
        (state) => state?.audit
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )

    useEffect(() => {
        if (entityId && entityType) {
            dispatch(fetchAuditData(
                { 'entityId': entityId, 'entityType': entityType }
            ))
        }
    }, [entityId, entityType])

    const handleModalClose = () => {
        dispatch(resetAuditData())
    }

    const columns = [
        {
            accessor: "fieldName",
            title: "Field Name",
            width: "10rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.fieldName || '--'
        },
        {
            accessor: "fieldOldValue",
            title: "Old Value",
            width: "8rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.fieldOldValue || '--'
        },
        {
            accessor: 'fieldNewValue',
            title: "New Value",
            width: "10rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.fieldNewValue || '--'
        },
        {
            accessor: 'modifiedBy',
            title: "Update By",
            width: "10rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.modifiedBy || '--'
        },
        {
            accessor: 'modifiedAt',
            title: "Updated At",
            width: "10rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
            render: (item) => item?.modifiedAt ? getDateWithTimeStamp(item?.modifiedAt, user) : '--'
        }
    ]

    return (
        <>
            {isMobile ? (
                <Drawer
                    open={entityType ? true : false}
                    onClose={handleModalClose}
                    anchor={'bottom'}
                >
                    <Wrapper isMobile={isMobile}>
                        <div className='container'>
                            <div className='content'>
                                <DataTable columns={columns} data={auditData || []} isCollpsable={false} showPagination={false} />
                            </div>
                        </div>
                        {loading ? <Loader isLoading={loading} /> : null}
                    </Wrapper>
                </Drawer>
            ) : (
                <Modal
                    open={entityType ? true : false}
                    onClose={handleModalClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: isMobile && '1rem'
                    }}

                >
                    <>
                        <Wrapper isMobile={isMobile}>
                            <div className='container'>
                                <div className='content'>
                                    <DataTable columns={columns} data={auditData || []} isCollpsable={false} showPagination={false} />
                                </div>
                            </div>
                            {loading ? <Loader isLoading={loading} /> : null}
                        </Wrapper>
                    </>
                </Modal>)}
        </>
    )
}