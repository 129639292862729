import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { SelectField } from 'component/common/Input/SelectField'
import { updateRoaster } from 'features/dailyRoaster/dailyRoasterAction'
import { getDriverList } from 'utils/common'
import { fetchDriversForRoaster } from 'features/driverManagement/driverManagementAction'

const formInitialState = {
    driver: '',
}

const DriverCellRenderer = ({ route }) => {
    const dispatch = useDispatch();
    const { driversForRoasters } = useSelector(
        (state) => state?.driverManagement
    )
    const [formValues, setFormValues] = useState(formInitialState);

    const handleMenuOpen = () => {
        dispatch(fetchDriversForRoaster(route?.dailyRouteScanSummaryVM?.routeId))
    };

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        const data = {}
        const driver = event.target?.value
        data['driverId'] = driver?.value || route?.driverId
        data['routeId'] = route?.dailyRouteScanSummaryVM?.routeId
        data['operation'] = driver?.value ? 'assign' : 'remove'
        if (data?.driverId) {
            dispatch(updateRoaster(data))
        }
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (route?.driverId) {
            setFormValues({
                driver: { label: route?.driverName, value: route?.driverId },
            });
        }
        else {
            setFormValues({
                driver: '',
            });
        }
    }, [route?.driverId])

    return (
        <SelectField name='driver' key={route?.dailyRouteScanSummaryVM?.routeId} options={getDriverList(driversForRoasters)} width={'12rem'}
            value={formValues?.driver} handleFieldChange={handleFieldChange} isClearable={true} onFocus={handleMenuOpen} menuPosition={'fixed'} menuPlacement={'bottom'}/>
    );
};

export default DriverCellRenderer
