import { urls } from 'utils/urls'

export const parseNotificationBody = (originalJson) => {
  try {
    const { title, body } = originalJson;
    const parsedBody = JSON.parse(body);
    const { message, id } = parsedBody;
    // Replace the id in the message with a link
    const messageWithLink = `${message} <a href='#' id="${id}">${id}</a>`

    // Return the modified JSON object
    return {
      title,
      body: {
        message: messageWithLink,
        id,
        url: body.url
      }
    };

  } catch (error) {
    console.error('Error parsing notification body:', error);
    return null;
  }
};


export const getRedirectURL = (data) => {
  const body = JSON.parse(data)
  const url = urls[body?.url]
  if (url) {
    const redirectUrl = `${url}?${body?.type}=${body?.id}`
    return redirectUrl;
  } else {
    return null;
  }
}
