export const manifestStatusEnum = {
    REVIEW: 'REVIEW',
    ARRIVED: 'ARRIVED',
    INITIAL_PARSED_TO_PACKAGE: 'INITIAL_PARSED_TO_PACKAGE',
    STREET_PERFECT_SUBMISSION_SUCCESS: 'STREET_PERFECT_SUBMISSION_SUCCESS',
    STREET_PERFECT_SUBMISSION_FAILED: 'STREET_PERFECT_SUBMISSION_FAILED',
    STREET_PERFECT_CONTENT_NULL: 'STREET_PERFECT_CONTENT_NULL',
    STREET_PERFECT_PROCESS_TRIGGER_SUCCESS: 'STREET_PERFECT_PROCESS_TRIGGER_SUCCESS',
    STREET_PERFECT_PROCESS_TRIGGER_FAILED: 'STREET_PERFECT_PROCESS_TRIGGER_FAILED',
    STREET_PERFECT_GOOD_FILE_DOWNLOAD_FAILED: 'STREET_PERFECT_GOOD_FILE_DOWNLOAD_FAILED',
    STREET_PERFECT_BAD_FILE_DOWNLOAD_FAILED: 'STREET_PERFECT_BAD_FILE_DOWNLOAD_FAILED',
    STREET_PERFECT_GOOD_FILE_DOWNLOAD_SUCCESS: 'STREET_PERFECT_GOOD_FILE_DOWNLOAD_SUCCESS',
    STREET_PERFECT_BAD_FILE_DOWNLOAD_SUCCESS: 'STREET_PERFECT_BAD_FILE_DOWNLOAD_SUCCESS',
    PARSED_STREET_PERFECT_GOOD_FILE: 'PARSED_STREET_PERFECT_GOOD_FILE',
    PARSED_STREET_PERFECT_GOOD_FILE_FAILED: 'PARSED_STREET_PERFECT_GOOD_FILE_FAILED',
    PARSED_STREET_PERFECT_BAD_FILE: 'PARSED_STREET_PERFECT_BAD_FILE',
    PARSED_STREET_PERFECT_BAD_FILE_FAILED: 'PARSED_STREET_PERFECT_BAD_FILE_FAILED',
    CORRECTED: 'CORRECTED',
    ROUTE_RESOLVED: 'ROUTE_RESOLVED',
    SCHEDULED: 'SCHEDULED',
    MISSING: 'MISSING',
    PARSED_WITH_ERROR: 'PARSED_WITH_ERROR',
    STREET_PERFECT_PROCESSING_COMPLETE: 'STREET_PERFECT_PROCESSING_COMPLETE'
}

export const manifestStatus = [
    {
        label: 'Review',
        value: manifestStatusEnum?.REVIEW
    },
    {
        label: 'Arrived',
        value: manifestStatusEnum?.ARRIVED
    },
    {
        label: 'Initial Parsed To Package',
        value: manifestStatusEnum?.INITIAL_PARSED_TO_PACKAGE
    },
    {
        label: 'Street Perfect Submission Success',
        value: manifestStatusEnum?.STREET_PERFECT_SUBMISSION_SUCCESS
    },
    {
        label: 'Street Perfect Submission Failed',
        value: manifestStatusEnum?.STREET_PERFECT_SUBMISSION_FAILED
    },
    {
        label: 'Street Perfect Content Null',
        value: manifestStatusEnum?.STREET_PERFECT_CONTENT_NULL
    },
    {
        label: 'Street Perfect Process Trigger Success',
        value: manifestStatusEnum?.STREET_PERFECT_PROCESS_TRIGGER_SUCCESS
    },
    {
        label: 'Street Perfect Process Trigger Failed',
        value: manifestStatusEnum?.STREET_PERFECT_PROCESS_TRIGGER_FAILED
    },
    {
        label: 'Street Perfect Good File Download Failed',
        value: manifestStatusEnum?.STREET_PERFECT_GOOD_FILE_DOWNLOAD_FAILED
    },
    {
        label: 'Street Perfect Bad File Download Failed',
        value: manifestStatusEnum?.STREET_PERFECT_BAD_FILE_DOWNLOAD_FAILED
    },
    {
        label: 'Street Perfect Good File Download Success',
        value: manifestStatusEnum?.STREET_PERFECT_GOOD_FILE_DOWNLOAD_SUCCESS
    },
    {
        label: 'Street Perfect Bad File Download Success',
        value: manifestStatusEnum?.STREET_PERFECT_BAD_FILE_DOWNLOAD_SUCCESS
    },
    {
        label: 'Parsed Street Perfect Good File',
        value: manifestStatusEnum?.PARSED_STREET_PERFECT_GOOD_FILE
    },
    {
        label: 'Parsed Street Perfect Good File Failed',
        value: manifestStatusEnum?.PARSED_STREET_PERFECT_GOOD_FILE_FAILED
    },
    {
        label: 'Parsed Street Perfect Bad File',
        value: manifestStatusEnum?.PARSED_STREET_PERFECT_BAD_FILE
    },
    {
        label: 'Parsed Street Perfect Bad File Failed',
        value: manifestStatusEnum?.PARSED_STREET_PERFECT_BAD_FILE_FAILED
    },
    {
        label: 'Corrected',
        value: manifestStatusEnum?.CORRECTED
    },
    {
        label: 'Route Resolved',
        value: manifestStatusEnum?.ROUTE_RESOLVED
    },
    {
        label: 'Scheduled',
        value: manifestStatusEnum?.SCHEDULED
    },
    {
        label: 'Missing',
        value: manifestStatusEnum?.MISSING
    },
    {
        label: 'Parsed With Error',
        value: manifestStatusEnum?.PARSED_WITH_ERROR
    },
    {
        label: 'Street Perfect Processing Complete',
        value: manifestStatusEnum?.STREET_PERFECT_PROCESSING_COMPLETE
    }
]