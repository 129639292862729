import * as React from 'react';
import { DialogContent, DialogActions, Button, Dialog, DialogTitle, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const ConfirmationModal = ({ open, title, message, onConfirm, onClose, selectedId }) => {
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle data-testid={`${title}`} variat='success'>
                <Typography color={'red'} gutterBottom>
                    {title}
                </Typography>
                <IconButton
                    aria-label="close" dataTestId='confirmation-close-icon'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent data-testid={`modal-message`}>
                <Typography variant="h6" gutterBottom>
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button data-testid={`cancel-button`} onClick={onClose} variant='outlined' color="primary">
                    Cancel
                </Button>
                <Button data-testid={`confirm-button`} onClick={() => onConfirm(selectedId)} variant='outlined' color="primary">
                    Confirm
                </Button>
            </DialogActions>

        </Dialog >
    );
}