import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchReturnTerminalData } from "features/route/routeAction";
import { CardDetail } from "component/dashboard/cardDetail";
import DataTable from "component/dataTable/dataTable";
import { Layout } from "component/common/Layout/Layout";
import { TableHeader } from "component/dataTable/tableHeader";
import { Button, Link } from "@mui/material";
import { TableFilter } from "component/dataTable/tableFilter";
import {
  iconStyle,
  handleApplyFilter,
  checkPermission,
  formatDate,
} from "utils/common";
import { Audit } from "component/audit/audit";
import { setToteFilter } from "features/warehouseOperation/warehouseOperationSlice";
import { setPackageFilter, resetSuccessState, resetError, clearSelectedPackages } from "features/packages/packagesSlice";
import { ResponseModal } from "component/common/Modal/ResponseModal";
import { RouteSummaryDetails } from "./RouteSummaryDetails";
import { urls } from "utils/urls";
import useFieldChange from "hooks/useFieldChange";
import { PrimaryButton } from "component/common/Button/Button";
import { RescheduleDelivery } from "./ReschedulePackage";
import { returnTerminalPackageStatus, returnTerminalPackageStatusFilterOptions } from "constants/packageStatus";
import { scanPackage, reclaimTotes } from "features/packages/packagesAction";
import { TextField } from "component/common/Input/TextField";
import { checkToteIDFormat } from "utils/returnTerminalHelper";
import { Loader } from "component/common/Loader/Loader";

const rowKey = "routeId";

const formInitialState = {
  driverName: "",
  trackingNumber: "",
  routeId:"",
  returnStatus: "",
  deliveryDate: "",
  clientName: "",
};

export const ReturnTerminal = () => {
  const dispatch = useDispatch();
  const { returnTerminalData, error, routeCount } = useSelector(
    (state) => state?.route
  );

  const { error: packageError } = useSelector((state) => state?.packages);

  const { selectedPackages, updatePackageSuccess, reclaimToteSuccess } =
    useSelector((state) => state?.packages);
  const { isMobile } = useSelector((state) => state?.viewport);
  const { loading } = useSelector((state) => state?.route);

  const { user } = useSelector((state) => state?.auth);

  const navigate = useNavigate();
  const [applyFilter, setApplyFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [sorteData, setSortedData] = useState(returnTerminalData);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [modalProps, setModalProps] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [formValues, setFormValues, handleFieldChange] =
    useFieldChange(formInitialState);
  const [formError, setFormErrors] = useState({});
  const [showUpdateModal, setShowModal] = useState(false);
  const [trackingID, setTrackingID] = useState("");
  const [isChecked, setIsChecked] = useState(true);

  const hasEditPermission = checkPermission(user, "RETURN TERMINAL");
  const fetchData = () => {
    const params = {
      page: page,
      size: rowsPerPage,
      widget: "RETURNS_TERMINAL",
    };
    if (formValues?.driverName) {
      params["driverName"] = formValues?.driverName?.trim();
    }
    if (formValues?.returnStatus) {
      params["packageStatus"] = formValues?.returnStatus?.value;
    }
    if (formValues?.trackingNumber) {
      params["trackingNumber"] = formValues?.trackingNumber?.trim();
    }
    if (formValues?.routeId) {
      params["routeId"] = formValues?.routeId?.trim();
    }
    if (formValues?.deliveryDate) {
      params["deliveryDate"] = formatDate(formValues?.deliveryDate) || null;
    }
    if (formValues?.clientName) {
      params["businessName"] = formValues?.clientName?.trim();
    }
    dispatch(
      fetchReturnTerminalData({
        page: page,
        size: rowsPerPage,
        ...params,
      })
    );
  };

  const hasPackagesToUpdate = (selectedPackages) => {
    return selectedPackages.some((route) => route?.packageIds?.length > 0);
  };

  useEffect(() => {
    return () => {
      dispatch(clearSelectedPackages());
    };
  }, [dispatch]);

  useEffect(() => {
    setIsChecked(!hasPackagesToUpdate(selectedPackages));
  }, [selectedPackages]);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, applyFilter]);

  useEffect(() => {
    if (updatePackageSuccess || reclaimToteSuccess) {
      setModalProps(
        {
          title: 'Success',
          message: (updatePackageSuccess && 'Parcel Updated Successfully!') || (reclaimToteSuccess && 'Totes Reclaimed Successfully!'),
          open: true,
          type: 'success'
        }
      )
      const redirectTimer = setTimeout(() => {
        dispatch(resetSuccessState())
        setTrackingID("");
        fetchData()
        dispatch(clearSelectedPackages());
        setModalProps(null)
      }, 1000);
      return () => {
        clearTimeout(redirectTimer);
      }
    }
  }, [updatePackageSuccess,reclaimToteSuccess]);

  useEffect(() => {
    if (error || packageError) {
      setModalProps({
        title: "Error Occured!",
        message: error || packageError,
        open: true,
        type: error,
      });
    }
  }, [error || packageError]);

  useEffect(() => {
    return () => {
      // This code will run when the component unmounts
      dispatch(resetError());
    };
  }, []);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleResetFilter = () => {
    setFormValues(formInitialState);
    setFormErrors({});
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const handleApplyFilterClick = () => {
    handleApplyFilter(
      formValues,
      formInitialState,
      setFormErrors,
      setApplyFilter,
      setPage,
      applyFilter
    );
  };

  const handleNavigation = (params) => {
    const { showTote, ...rest } = params;
    if (showTote) {
      dispatch(setToteFilter(rest));
      navigate(urls?.TOTES_WIDGET_URL);
    } else {
      dispatch(setPackageFilter(rest));
      navigate(urls?.PARCEL_WIDGET_URL);
    }
  };

  const handlePackageStatusUpdateCick = () => {
    setShowModal(!showUpdateModal);
  };

  const handleTrackingIDChange = (event) => {
    setTrackingID(event.target.value);
  };

  const handleSubmitClick = () => {

    if (checkToteIDFormat(trackingID.trim())) {
      dispatch(reclaimTotes([trackingID.trim()]));
    } else {
      dispatch(scanPackage(trackingID.trim()));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitClick();
    }
  };

  const trackingIDField = {
    value: trackingID,
    handleFieldChange: handleTrackingIDChange,
    type: "text",
    width: "12rem",
    name: "trackingID",
    error: "",
    placeholder: "Tracking ID / Tote Id",
    onKeyDown: handleKeyDown,
  };

  const columns = [
    {
      accessor: "routeId",
      title: "Route ID",
      width: "4rem",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original?.routeId || "--",
      render: (item) => item?.routeId || "--",
    },
    {
      accessor: "routeName",
      title: "Route Name",
      width: "4rem",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original?.routeName || "--",
      render: (item) => item?.routeName || "--",
    },
    {
      accessor: "driverId",
      title: "Driver ID",
      width: "3rem",
      Cell: ({ cell: { value } }) => value || "--",
      render: (item) => item?.driverId || "--",
    },
    {
      accessor: "driverName",
      title: "Driver Name",
      width: "3rem",
      Cell: ({ cell: { value } }) => value || "--",
      render: (item) => item?.driverName || "--",
    },
    {
      accessor: "fsaZone",
      title: "FSA Zone",
      width: "3rem",
      Cell: ({ cell: { value } }) => value || "--",
      render: (item) => item?.fsaZone || "--",
    },
    {
      accessor: "totalTotes",
      title: "Total Totes",
      width: "2rem",
      Cell: ({
        cell: {
          row: { original },
        },
      }) =>
        original?.totalTotes ? (
          <Link
            id="dailyRouteId"
            onClick={() =>
              handleNavigation({
                routeId: original?.routeId,
                showTote: true,
              })
            }
            style={iconStyle}
            sx={{ textDecoration: "none" }}
          >
            {original?.totalTotes}
          </Link>
        ) : (
          original?.totalTotes
        ),
      render: (item) => item?.totalTotes,
    },
    {
      accessor: "unscannedTotes",
      title: "Reclaimed Totes",
      width: "2rem",
      isSortable: false,
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original?.totalTotes - original?.unScannedToteIds?.length,
      render: (item) => item?.totalTotes - item?.unScannedToteIds?.length,
    },
    {
      accessor: "missingOrderIds",
      title: "Missing Orders",
      width: "2rem",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original?.missingOrderIds?.length,
      render: (item) => item?.missingOrderIds?.length,
    },
    {
      accessor: "returnedPackages",
      title: "Returned Parcels",
      width: "2rem",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original?.returnedPackages?.length,
      render: (item) => item?.returnedPackages?.length,
    },
    {
      accessor: "unScannedPackageIds",
      title: 'Return Pending Orders',
      width: "2rem",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original?.unScannedPackageIds?.length,
      render: (item) => item?.unScannedPackageIds?.length,
    },
  ]?.filter(Boolean);

  const tableFilterProps = {
    fields: [
      {
        label: "Driver Name",
        value: formValues?.driverName,
        handleFieldChange: handleFieldChange,
        type: "text",
        width: isMobile ? "9rem" : "12rem",
        name: "driverName",
        error: formError?.driverName,
        placeholder: "Driver Name",
      },
      {
        label: "Tracking Number",
        value: formValues?.trackingNumber,
        handleFieldChange: handleFieldChange,
        type: "text",
        width: isMobile ? "9rem" : "12rem",
        name: "trackingNumber",
        error: formError?.trackingNumber,
        placeholder: "Tracking Number",
      },
      {
        label: 'Route Id',
        value: formValues?.routeId,
        handleFieldChange: handleFieldChange,
        type: 'text',
        width: isMobile ? '9rem' : '12rem',
        name: 'routeId',
        error: formError?.routeId,
        placeholder: 'Route Id'
      },
      {
        label: "Return Status",
        name: "returnStatus",
        type: "select",
        options: returnTerminalPackageStatusFilterOptions,
        value: formValues?.returnStatus || "",
        handleFieldChange: handleFieldChange,
        error: formError?.returnStatus,
        width: isMobile ? "9rem" : "12rem",
      },
      {
        label: "Reschedule Date",
        value: formValues?.deliveryDate,
        handleFieldChange: handleFieldChange,
        type: "date",
        width: isMobile ? "9rem" : "12rem",
        name: "deliveryDate",
        placeholder: "DD-MM-YYYY",
        error: formError?.deliveryDate,
      },
      {
        label: "Client Name",
        value: formValues?.clientName,
        handleFieldChange: handleFieldChange,
        type: "text",
        width: isMobile ? "9rem" : "12rem",
        name: "clientName",
        error: formError?.clientName,
        placeholder: "Client Name",
      },
    ],
    buttons: [
      {
        label: "Apply",
        variant: "contained",
        color: "white",
        backgroundColor: "black",
        onClick: handleApplyFilterClick,
      },
      {
        label: "Reset",
        variant: "contained",
        color: "white",
        backgroundColor: "blue",
        onClick: handleResetFilter,
      },
    ],
    margin: "0px",
    padding: "10px",
    borderRadius: "10px",
  };

  return (
    <Layout
      headerTitle={"Returns Terminal"}
      showBackArrow={false}
      action={
        hasEditPermission ? (
          <div>
            <PrimaryButton
              type="button"
              label={"Update"}
              height={"30px"}
              onClick={handlePackageStatusUpdateCick}
              disabled={isChecked}
            />
          </div>
        ) : null
      }
    >
      <CardDetail>
        <div className="container">
          <TableHeader
            columns={updatedColumns}
            data={sorteData}
            exportFileName="Return Terminal"
            onRefreshClick={handleApplyFilterClick}
            action={
              <div className="action">
                <TextField {...trackingIDField} />
                <Button
                  data-testid="submit-button"
                  label={"Submit"}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitClick}
                  disabled={loading || !trackingID}
                >
                  Submit
                </Button>
              </div>
            }
          />
          <TableFilter {...tableFilterProps} />
          <div className="content">
            <DataTable
              columns={columns}
              data={returnTerminalData || []}
              isCollpsable={true}
              showPagination={returnTerminalData?.length ? true : false}
              onPageChange={handlePageChange}
              page={page}
              totalRowsCount={routeCount}
              onSortChange={setSortedData}
              uniqueKey={rowKey}
              setUpdatedColumns={setUpdatedColumns}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              childComponent={RouteSummaryDetails}
              rowKey={rowKey}
            />
          </div>
        </div>
      </CardDetail>
      <Audit />
      {showUpdateModal ? (
        <RescheduleDelivery
          isModelOpen={showUpdateModal}
          handleClose={handlePackageStatusUpdateCick}
          allowedStatuses={returnTerminalPackageStatus}
        />
      ) : null}
      {loading ? <Loader isLoading={loading} /> : null}
      {modalProps ? (
        <ResponseModal
          {...modalProps}
          handleClose={() => setModalProps(null)}
        />
      ) : null}
    </Layout>
  );
};
