import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataCenters, fetchTenants } from 'features/userManagement/userManagementAction'
import { getVehicles } from 'utils/common'
import { driverTypeConstants } from 'constants/driverTypeConstants'
import { vehicleTypeConstants } from 'constants/vehicleTypeConstants'
import { serviceStatusConstants } from 'constants/serviceStatusConstants'
import { fetchVehiclesWithoutDrivers } from 'features/vehicle/vehicleAction'
import { currencies } from 'constants/currencyConstants';

export const DriverFormFields = ({ formValues,isEdit, formError, handleFieldChange }) => {
    const dispatch = useDispatch();
    const { loading, dataCenters, tenants } = useSelector(
        (state) => state?.userManagement
    )
    const { vehiclesWithoutDrivers, loading: vehiclesLoading } = useSelector(
        (state) => state?.vehicles
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )

    // Fetching data again here - if user is on add.edit page and refresh, thise data should be available
    useEffect(() => {
        if (!dataCenters?.length) {
            dispatch(fetchDataCenters())
        }
    }, [dataCenters])

    useEffect(() => {
        if (!tenants?.length) {
            dispatch(fetchTenants())
        }
    }, [tenants])

    useEffect(() => {
        dispatch(fetchVehiclesWithoutDrivers({widget: 'driver'}))
    }, [])

    return {
        fieldList: [
            {
                title: 'Personal Detail',
                fields: [
                    isEdit && {
                        label: 'Employee Id',
                        name: 'id',
                        required: true,
                        type: 'text',
                        value: formValues?.id || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.id,
                        width: isMobile ? '17rem' : '12rem',
                        disabled: true
                    },
                    {
                        label: `Driver's License Number`,
                        name: 'licenseNumber',
                        required: true,
                        type: 'text',
                        value: formValues?.licenseNumber || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.licenseNumber,
                        maxLength: 50,
                        width: isMobile ? '17rem' : '12rem'
                    },
                    {
                        label: 'Home Phone',
                        name: 'homePhone',
                        required: true,
                        type: 'text',
                        value: formValues?.homePhone || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.homePhone,
                        maxLength: 30,
                        width: isMobile ? '17rem' : '12rem'
                    },
                ]
            },
            {
                title: 'Work Details',
                fields: [
                    {
                        label: 'Driver Type',
                        name: 'driverType',
                        required: true,
                        type: 'select',
                        options: driverTypeConstants,
                        value: formValues?.driverType || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.driverType,
                        width: isMobile ? '17rem' : '12rem'
                    },
                    {
                        label: 'Vehicle Type',
                        name: 'vehicleType',
                        required: true,
                        type: 'select',
                        options: vehicleTypeConstants,
                        value: formValues?.vehicleType || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.vehicleType,
                        width: isMobile ? '17rem' : '12rem'
                    },
                    {
                        label: 'Work Email',
                        name: 'workEmail',
                        required: true,
                        type: 'email',
                        value: formValues?.workEmail || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.workEmail,
                        maxLength: 254,
                        width: isMobile ? '17rem' : '12rem'
                    },
                    {
                        label: 'Work Phone',
                        name: 'workPhone',
                        required: true,
                        type: 'text',
                        value: formValues?.workPhone || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.workPhone,
                        maxLength: 30,
                        width: isMobile ? '17rem' : '12rem'
                    },
                    {
                        label: 'Hire Date',
                        value: formValues?.onBoardingDate,
                        handleFieldChange: handleFieldChange,
                        type: 'date',
                        name: 'onBoardingDate',
                        placeholder: 'DD-MM-YYYY',
                        error: formError?.onBoardingDate,
                        required: true,
                        min: new Date()?.toISOString()?.split('T')?.[0],
                        width: isMobile ? '17rem' : '12rem'
                    },
                    {
                        label: 'Inservice Status',
                        name: 'inServiceStatus',
                        required: true,
                        type: 'select',
                        options: serviceStatusConstants,
                        value: formValues?.inServiceStatus || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.inServiceStatus,
                        width: isMobile ? '17rem' : '12rem'
                    },
                    {
                        label: 'Rate per Hour',
                        name: 'ratePerHr',
                        required: true,
                        type: 'text',
                        value: formValues?.ratePerHr || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.ratePerHr,
                        maxLength: 30,
                        width: isMobile ? '17rem' : '12rem'
                    },
                    {
                        label: 'Currency Unit',
                        name: 'currencyUnit',
                        required: true,
                        type: 'select',
                        options: currencies,
                        value: formValues?.currencyUnit || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.currencyUnit,
                        width: isMobile ? '17rem' : '12rem'
                    },
                    {
                        label: 'Vehicle',
                        name: 'vehicle',
                        type: 'select',
                        options: getVehicles(vehiclesWithoutDrivers, false),
                        value: formValues?.vehicle || '',
                        handleFieldChange: handleFieldChange,
                        error: formError?.vehicle,
                        width: isMobile ? '17rem' : '12rem',
                        isLoading: vehiclesLoading,
                        isClearable: true
                    }
                ]
            },
            {
                title: 'Address Details',
                fields: [
                    {
                        label: 'Address One',
                        name: 'addressOne',
                        required: true,
                        type: 'text',
                        value: formValues?.addressOne || '',
                        handleFieldChange: handleFieldChange,
                        width: isMobile ? '17rem' : '12rem',
                        error: formError?.addressOne,
                    },
                    {
                        label: 'Address Two',
                        name: 'addressTwo',
                        required: false,
                        type: 'text',
                        value: formValues?.addressTwo || '',
                        handleFieldChange: handleFieldChange,
                        width: isMobile ? '17rem' : '12rem',
                        error: formError?.addressTwo,
                    },
                    {
                        label: 'City',
                        name: 'city',
                        required: true,
                        type: 'text',
                        value: formValues?.city || '',
                        handleFieldChange: handleFieldChange,
                        width: isMobile ? '17rem' : '12rem',
                        error: formError?.city,
                    },
                    {
                        label: 'Province',
                        name: 'province',
                        required: true,
                        type: 'text',
                        value: formValues?.province || '',
                        handleFieldChange: handleFieldChange,
                        width: isMobile ? '17rem' : '12rem',
                        error: formError?.province,
                    },
                    {
                        label: 'Postal Code',
                        name: 'postalCode',
                        required: true,
                        type: 'text',
                        value: formValues?.postalCode || '',
                        handleFieldChange: handleFieldChange,
                        width: isMobile ? '17rem' : '12rem',
                        error: formError?.postalCode,
                    }
                ]
            }
        ],
        loading: loading,
        margin: '0px',
        padding: '1rem'
    }
}