import { environment } from "../environments/environment.jsx";
import axios from "axios";
import { getHeader } from './authUtils.js'

const apiEndpoint = environment.webservices.endpoint;

export class ManifestService {
    getAllManifest(queryParams){
        const endpoint = `${apiEndpoint}/api/manifests`;
        return axios.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }
    getManifestDetails(id){
        const endpoint = `${apiEndpoint}/api/manifests/${id}/summary`; 
        return axios.get(endpoint, {
            headers: getHeader(),
        });
    }
    uploadManifest(payload){
        const endpoint = `${apiEndpoint}/api/manifests`;
        return axios.post(endpoint, payload, {
            headers: getHeader(),
        });
    }

    getBusinessDetails(queryParams){
        const endpoint = `${apiEndpoint}/api/customer/unbatched`;
        return axios.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        })
    }
}