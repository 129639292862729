import React, { useState } from "react"
import { getDateWithTimeStamp } from 'utils/common'
import DataTable from 'component/dataTable/dataTable'
import { PackageDetails } from './PackageDetails';
import PanoramaIcon from '@mui/icons-material/Panorama';
import { PopupWithImage } from './PopUpWithImage'
import { routeStopStatusEnum } from 'constants/routeStatus'
import { Dot } from 'component/common/Dot/dot'
import { getStopColor } from 'utils/liveTrackingHelper'

import './liveTracking.scss';

export const RouteStopTable = ({ routeStops, user }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStop, setSelectedStop] = useState(null);

    const handleImageClick = (item) => {
        setOpenDialog(true);
        setSelectedStop(item)
    }

    const handlePopupClose = () => {
        setOpenDialog(false)
        setSelectedStop(null)
    }


    const sortedStops = routeStops.slice().sort((a, b) => {
        return a.routeStopSequence - b.routeStopSequence;
    });

    const columns = [{
        accessor: "routeStopSequence",
        title: "SQ No",
        Cell: ({ cell: { row: { original } } }) => <><Dot color={getStopColor(original?.routeStopStatus)} /> {original?.routeStopSequence || '--'}</>,
        render: (item) => item?.routeStopSequence
    },
    {
        accessor: "routeStopAddress",
        title: "Address",
        width: "4rem",
        Cell: ({ cell: { value } }) => value,
        render: (item) => item?.routeStopAddress
    },
    {
        accessor: "estimatedArrival",
        title: "ET",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => getDateWithTimeStamp(original?.estimatedArrival, user, true),
        render: (item) => getDateWithTimeStamp(item?.estimatedArrival, user, true)
    },
    {
        accessor: "actualArrivalTime",
        title: "AT",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => getDateWithTimeStamp(original?.actualArrivalTime, user, true),
        render: (item) => getDateWithTimeStamp(item?.actualArrivalTime, user, true)
    },
    {
        accessor: "stopPhoto",
        title: "Stop Photo",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.routeStopStatus === routeStopStatusEnum?.ROUTE_STOP_SERVED ? <PanoramaIcon sx={{ cursor: 'pointer', height: 15, width: 15, marginRight: '10px' }} onClick={() => handleImageClick(original)} /> : '--'
        ,
        render: (item) => '--'
    }
    ]

    return (
        <>
            <DataTable childComponent={PackageDetails} isCollpsable={true} columns={columns} allowSorting={false} maxHeight={'inherit'} data={sortedStops} rowKey={'routeStopId'} showPagination={false} />
            {openDialog ? <PopupWithImage routeStop={selectedStop} open={openDialog} onClose={handlePopupClose} /> : null}
        </>

    )

}
