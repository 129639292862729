import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { rescheduleRoute } from 'features/route/routeAction'
import 'component/dailyRoaster/AddDuty.scss'
import { getUTCTime, getUserTimeZone } from 'utils/common';
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';
import { CustomForm } from 'component/common/Form/CustomForm'

const formInitialState = {
    routeRescheduleTime: '',
    routeId: '',
    sla: false
}

const checkBoxOptions = {
    sla: 'SLA',
};

export const RescheduleRoute = ({ isModelOpen, handleClose, route, user, tabName }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const { routeRescheduleSuccess, loading } = useSelector(
        (state) => state?.route
    )
    const userTimeZone = getUserTimeZone(user?.distributionCenterResponseVm?.timeZone) || 'America/Toronto'

    useEffect(() => {
        if (routeRescheduleSuccess) {
            handleClose()
        }
    }, [routeRescheduleSuccess]);

    useEffect(() => {
        if (route) {
            setFormValues((prevValues) => ({
                ...prevValues,
                routeId: route,
            }));
        }
    }, [route])

    const handleDateChange = (date) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            routeRescheduleTime: date,
        }));
    };

    const handleFormSubmit = () => {
        const notRequiredFields = []
        const errors = validateForm(formValues, notRequiredFields);
        setFormErrors(errors)
        if (!Object.keys(errors)?.length) {
            setFormErrors({})
            const data = {
                routeId: route,
                zoneDateTime: getUTCTime(formValues?.routeRescheduleTime, userTimeZone),
                tabName
            }
            if (tabName === 'Reschedule_Route') {
                data['sla'] = formValues?.sla
            }
            dispatch(rescheduleRoute(data))
        }
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: checked,
        }));
    };

    const formProps = {
        fields: [{
            label: 'Route ID / Batch ID',
            name: 'routeId',
            required: true,
            type: 'text',
            value: formValues?.routeId || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            error: formError?.routeId,
        },
        {
            label: 'Route Reschedule Time',
            name: 'routeRescheduleTime',
            required: true,
            type: 'datetime',
            value: formValues?.routeRescheduleTime || '',
            handleFieldChange: handleDateChange,
            width: '19rem',
            error: formError?.routeRescheduleTime,
            timeZone: userTimeZone
        },
        tabName === 'Reschedule_Route' && {
            type: 'checkbox',
            options: checkBoxOptions,
            value: formValues,
            handleChange: handleCheckboxChange,
            width: '19rem'
        }
        ],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: 'center'
    }

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div className='add-duty-modal'>
                <div><span className='title'>{'Reschedule Route'}</span></div>
                <CustomForm {...formProps} />
            </div>
        </CustomModal>
    )

}