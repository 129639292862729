import React, { useEffect, useRef, useMemo, useState } from 'react';
import { getMapBoxAccessKey } from "utils/common";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { convertDataToGeoJson, getStopColor, createPopupContent, fetchRoutesInBatches } from 'utils/liveTrackingHelper'
import 'mapbox-gl/dist/mapbox-gl.css';
import { useSelector } from 'react-redux';
import { PopupWithImage } from './PopUpWithImage'

const wareHouseMarkerElement = document.createElement('div');
const width = 50;
const height = 50;
wareHouseMarkerElement.className = 'marker';
wareHouseMarkerElement.style.backgroundImage = `url(static/images/warehouse.png)`;
wareHouseMarkerElement.style.width = `${width}px`;
wareHouseMarkerElement.style.height = `${height}px`;
wareHouseMarkerElement.style.backgroundSize = '100%';

const apikey = getMapBoxAccessKey()

const getInitials = (name) => {
    // Split the name by spaces to get individual names
    const names = name?.trim()?.split(' ')?.slice(0, 2);

    // Get the first character of each name and join them together
    const initials = names?.map((n) => n?.charAt(0)?.toUpperCase())?.join('');
    return initials;
}

const createSVGMarkerLabel = (textToShow) => {
    var svgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="35" viewBox="0 0 28 35"><path id="location_on_FILL1_wght400_GRAD0_opsz48" d="M174-845a58.177,58.177,0,0,1-10.522-11.134Q160-861.275,160-865.65a13.534,13.534,0,0,1,4.222-10.456A13.985,13.985,0,0,1,174-880a13.985,13.985,0,0,1,9.778,3.894A13.534,13.534,0,0,1,188-865.65q0,4.375-3.478,9.516A58.178,58.178,0,0,1,174-845Z" transform="translate(-160 880)" fill="#5d48ff"/><text x="14" y="20" font-size="12pt" ' +
        'font-family="Arial" text-anchor="middle" ' +
        'fill="white">' + textToShow + '</text></svg>';
    return svgMarkup;
}


// draw  a dom icon
const createSVGStopLabel = (textToShow, status) => {
    const color = getStopColor(status)
    var svgMarkup = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <rect stroke="white" fill="${color}" x="1" y="1" width="22" height="22" />
            <text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">${textToShow}</text>
        </svg>`;
    return svgMarkup;
}

const createCarIcon = () => {
    const svgMarkup = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z"/></svg>`
    return svgMarkup;
}

export const LiveTrackingMap = ({ data, showStops, routeStops, routeFollowed, currentLat, currentLon, center }) => {
    const mapContainer = useRef(null);
    const { user } = useSelector(
        (state) => state?.auth
    )
    const userDC = user?.distributionCenterResponseVm

    const mapRef = useRef(null);
    const liveTrackingData = convertDataToGeoJson(data)
    const driverLocationMarkers = useRef([]);
    const stopMarkers = useRef([]);
    const carMarker = useRef([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStop, setSelectedStop] = useState(null);

    const mapCenter = useMemo(() => {
        return center?.dcLat && center?.dcLon ? [center.dcLon, center.dcLat] : [userDC?.dcLon, userDC?.dcLat];
    }, [center?.dcLat, center?.dcLon]);

    useEffect(() => {
        mapboxgl.accessToken = apikey;
        mapRef.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            // center: [108, 4], // Example center coordinates
            center: mapCenter,
            zoom: 12, // Example zoom level
            projection: 'globe',
        });
        // Add zoom and rotation controls to the map.
        mapRef.current.addControl(new mapboxgl.NavigationControl({ showCompass: false } // Hide the compass (rotation control)
        ));

        // Cleanup when the component unmounts
        return () => {
            mapRef.current?.remove();
        }
    }, [mapCenter]);


    const getBoundigBox = (coordinates) => {
        // Initialize variables to store min and max values
        let minLongitude = 180; // Initialize with a high value
        let maxLongitude = -180; // Initialize with a low value
        let minLatitude = 90; // Initialize with a high value
        let maxLatitude = -90; // Initialize with a low value

        // Loop through the coordinates to find min and max values
        coordinates?.forEach(coord => {
            const [longitude, latitude] = coord;
            minLongitude = Math.min(minLongitude, longitude);
            maxLongitude = Math.max(maxLongitude, longitude);
            minLatitude = Math.min(minLatitude, latitude);
            maxLatitude = Math.max(maxLatitude, latitude);
        });

        // Create the bounding box using the min and max values
        const bounds = new mapboxgl.LngLatBounds([minLongitude, minLatitude], [maxLongitude, maxLatitude]);

        // Use fitBounds to adjust the map's viewport to the bounding box
        mapRef?.current?.fitBounds(bounds, { padding: 20 }); // You can adjust the padding as needed

    }

    useEffect(() => {
        try {

            //Remove all previous data
            driverLocationMarkers?.current?.forEach(marker => marker.remove());
            if (liveTrackingData?.features?.length && mapRef?.current && !showStops) {
                //Remove exisitng shosstops
                stopMarkers?.current?.forEach(marker => marker.remove());
                let coordinateArray = [];
                // Add or update markers to the map to show the driver's current location
                liveTrackingData?.features?.forEach((feature) => {
                    const coordinates = feature?.geometry?.coordinates;
                    coordinateArray.push([coordinates?.[0], coordinates?.[1]]);
                    // Create a custom SVG marker with the name
                    const svgMarker = document.createElement('div');
                    svgMarker.innerHTML = createSVGMarkerLabel(getInitials(feature?.properties?.name));
                    // Add or update the SVG marker as a custom image
                    const marker = new mapboxgl.Marker({ element: svgMarker }).setLngLat(coordinates);
                    driverLocationMarkers?.current?.push(marker)
                    marker.addTo(mapRef?.current);
                });
                getBoundigBox(coordinateArray);
            }
        }
        catch (error) {
            // Handle any errors that occur during map initialization
            console.error("Error during map initialization:", error);
        }
    }, [liveTrackingData, showStops]);

    // Make a Map Matching request
    async function getMatch(coordinates, radius, profile, routeStops) {
        const query = await fetch(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapboxgl.accessToken}&steps=true`,
            { method: 'GET' }
        );
        const response = await query?.json();
        // Handle errors
        if (response?.code !== 'Ok') {
            alert(
                `${response?.code} - ${response?.message}`
            );
            return;
        }
        const coords = response?.routes[0]?.geometry?.coordinates;

        // Create a GeoJSON feature from the route data
        const routeFeature = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: coords,
            },
        };

        // Add the route as a layer
        mapRef?.current?.addSource('route', {
            type: 'geojson',
            data: routeFeature,
        });

        mapRef?.current?.addLayer({
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': '#007CBF', // Adjust the color as needed
                'line-width': 5, // Adjust the line width as needed
            },
        });

        // Add markers for each coordinate
        routeStops?.forEach((stop) => {
            // Create a custom SVG marker with the name
            const svgMarker = document.createElement('div');
            svgMarker.innerHTML = createSVGStopLabel(stop?.routeStopSequence, stop?.routeStopStatus);

            // Create a popup with lat, lng data and thumbnail image
            const popupContent = createPopupContent(stop, user);
            const showDetailsBtn = popupContent.querySelector('#showDetailsBtn');

            // Handle click event on the thumbnail image to show full image
            if (showDetailsBtn) {
                showDetailsBtn.addEventListener('click', () => {
                    setOpenDialog(true);
                    setSelectedStop(stop)
                });
            }

            // Create a popup with lat, lng data and thumbnail image
            const popup = new mapboxgl.Popup().setDOMContent(popupContent);

            // Add or update the SVG marker as a custom image
            const marker = new mapboxgl.Marker({ element: svgMarker }).setLngLat([stop?.lon, stop?.lat]).setPopup(popup);
            marker.addTo(mapRef?.current);
            stopMarkers?.current?.push(marker);
        });

        // Fit the map to show the entire route
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
            coords?.[0],
            coords?.[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coords) {
            bounds.extend(coord);
        }

        mapRef?.current?.fitBounds(bounds, {
            padding: 20
        });
    }


    // Draw route from route stops co-ordinates
    useEffect(() => {
        if (routeStops?.length && showStops) {

            if (currentLat && currentLon) {
                carMarker?.current?.forEach(marker => marker.remove());
                mapRef?.current?.flyTo({
                    center: [currentLon, currentLat]
                });

                const svgMarker = document.createElement('div');
                svgMarker.innerHTML = createCarIcon();
                // Add or update the SVG marker as a custom image
                const currentLocationMarker = new mapboxgl.Marker({ element: svgMarker }).setLngLat([currentLon, currentLat]);
                carMarker?.current?.push(currentLocationMarker)
                currentLocationMarker.addTo(mapRef?.current);


                function toggleMarkerVisibility() {
                    if (currentLocationMarker._element.style.visibility === 'visible') {
                        currentLocationMarker._element.style.visibility = 'hidden';
                    } else {
                        currentLocationMarker._element.style.visibility = 'visible';
                    }
                }

                setInterval(toggleMarkerVisibility, 1000);
            }

            stopMarkers?.current?.forEach(marker => marker.remove());

            if (mapRef?.current?.getLayer('route')) {
                mapRef?.current?.removeLayer('route');
            }

            // Remove an existing source with the same ID if it exists
            if (mapRef?.current?.getSource('route')) {
                mapRef?.current?.removeSource('route');
            }

            const marker = new mapboxgl.Marker(wareHouseMarkerElement)
                .setLngLat([mapCenter?.[0], mapCenter?.[1]])
                .addTo(mapRef?.current);
            stopMarkers?.current?.push(marker)
            const coordinates = routeStops.map(item => [parseFloat(item.lon), parseFloat(item.lat)]);
            coordinates.push([parseFloat(mapCenter[0]), parseFloat(mapCenter[1])])
            // Convert the coordinate list to the desired format
            const formattedCoordinates = coordinates?.map(coord => `${coord?.[0]},${coord?.[1]}`).join(';');

            const radius = coordinates?.map(() => 25);
            const profile = 'driving-traffic'
            getMatch(formattedCoordinates, radius, profile, routeStops)
            getBoundigBox(coordinates)
        }

        if (!showStops) {
            carMarker?.current?.forEach(marker => marker.remove());
            stopMarkers?.current?.forEach(marker => marker.remove());
            if (mapRef?.current?.getLayer('route')) {
                mapRef?.current?.removeLayer('route');
            }

            // Remove an existing source with the same ID if it exists
            if (mapRef?.current?.getSource('route')) {
                mapRef?.current?.removeSource('route');
            }

            if (mapRef?.current?.getLayer('actual-route-followed')) {
                mapRef?.current?.removeLayer('actual-route-followed');
            }

            if (mapRef?.current?.getSource('route-followed')) {
                mapRef?.current?.removeSource('route-followed');
            }
        }
    }, [showStops, routeStops]);


    useEffect(() => {

        if (mapRef?.current?.getLayer('actual-route-followed')) {
            mapRef?.current?.removeLayer('actual-route-followed');
        }

        if (mapRef?.current?.getSource('route-followed')) {
            mapRef?.current?.removeSource('route-followed');
        }

        if (mapRef.current.isStyleLoaded() && routeFollowed?.length) {
            const coordinates = routeFollowed.map(item => [parseFloat(item.lon), parseFloat(item.lat)]);
            fetchRoutesInBatches(coordinates, apikey).then((allRoutes) => {
                mapRef.current.addSource('route-followed', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': allRoutes
                        }
                    }
                });
                mapRef.current.addLayer({
                    'id': 'actual-route-followed',
                    'type': 'line',
                    'source': 'route-followed',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': 'red',
                        'line-width': 5
                    }
                });
            });
        }
    }, [routeFollowed])

    const handlePopupClose = () => {
        setOpenDialog(false)
        setSelectedStop(null)
    }

    // Make sure the 'viewport' object is defined before using it
    return (
        <>
            <div ref={mapContainer} style={{ width: '100%', height: '70vh' }} />
            {/* // Dialog to display full image */}
            {openDialog ? <PopupWithImage routeStop={selectedStop} open={openDialog} onClose={handlePopupClose} /> : null}
        </>
    )
};